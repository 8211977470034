import {
  AccountNames,
  BidStatus,
  EmailFooter,
  EventStatus,
  InvoiceStatus,
  PaidStatus,
  Permissions,
  Permissions_IT,
  WebCastingStatus,
} from "./constants";
import { CollaboratorProfile, User } from "src/api/types";
import L from "i18n-react";
import { format, parse, isValid } from "date-fns";
import moment from "moment";
import { UserType } from "src/api/types";
import routes from "../config/routes";
import { DatabaseIcon } from "src/components/icons/database-icon";
import { CalendarStarIcon } from "src/components/icons/calendar-star-icon";
import { AssignIcon } from "src/components/icons/assign-icon";
import { ListCheckIcon } from "src/components/icons/list-check-icon";
import { CalendarIcon } from "src/components/icons/calendar";
import { WalletIcon } from "src/components/icons/wallet-icon";
import { ReceiptIcon } from "src/components/icons/receipt-icon";
import { DocumentIcon } from "src/components/icons/document-icon";
import { UserIcon } from "src/components/icons/user-icon";
import { UserLockIcon } from "src/components/icons/user-lock-icon";
import { AddressBookIcon } from "src/components/icons/address-book-icon";
import { WhatsAppIcon } from "src/components/icons/whatsapp-icon";

export const capitalizeFirstLetter = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getCapitalizedFirstLetter = (str: string): string => {
  if (str.length > 0) {
    return str.charAt(0).toUpperCase();
  } else {
    return "";
  }
};

function removeFalsy(obj: any) {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => Boolean(v)));
}

export function getAccountName(
  id: number,
  accounts: { id: number; name: string }[]
): string {
  const value = accounts.filter((item) => item.id == id);
  return value.length == 0 ? "-" : value[0].name;
}

export function getContractStatusBackgroundColor(value: string): string {
  const items = value.split("/");
  if (items.length < 2) {
    return "bg-gray-modern-50";
  } else {
    if (Number(items[0]) == 0) {
      return "bg-red-100";
    } else {
      if (Number(items[0]) < Number(items[1])) {
        return "bg-warning-100";
      } else {
        return "bg-success-100";
      }
    }
  }
}

export function getContractStatusTextColor(value: string): string {
  const items = value.split("/");
  if (items.length < 2) {
    return "text-white";
  } else {
    if (Number(items[0]) == 0) {
      return "text-error";
    } else {
      if (Number(items[0]) < Number(items[1])) {
        return "text-warning";
      } else {
        return "text-success";
      }
    }
  }
}

export function getEventStatusName(status: number): string {
  const value = EventStatus.filter((item) => item.id == status);
  return value[0].name;
}

export function getEventStatusColor(status: number): string {
  const value = EventStatus.filter((item) => item.id == status);
  return value[0].color;
}

export function getBidStatusName(status: number): string {
  const value = BidStatus.filter((item) => item.id == status);
  return value[0].name;
}

export function getBidStatusColor(status: number): string {
  const value = BidStatus.filter((item) => item.id == status);
  return value[0].color;
}

export function getWebCastingStatusName(status: number): string {
  const value = WebCastingStatus.filter((item) => item.id == status);
  return value[0].name;
}

export function getWebCastingStatusColor(status: number): string {
  const value = WebCastingStatus.filter((item) => item.id == status);
  return value[0].color;
}

export function getPaidStatusName(status: number): string {
  const value = PaidStatus.filter((item) => item.id == status);
  return value[0].name;
}

export function getPaidStatusColor(status: number): string {
  const value = PaidStatus.filter((item) => item.id == status);
  return value[0].color;
}

export function getInvoiceStatusName(status: number): string {
  const value = InvoiceStatus.filter((item) => item.id == status);
  return value[0].name;
}

export function getInvoiceStatusColor(status: number): string {
  const value = InvoiceStatus.filter((item) => item.id == status);
  return value[0].color;
}

export function formatFileSize(sizeInBytes: number): string {
  const units = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  let index = 0;
  let formattedSize = sizeInBytes;

  while (formattedSize >= 1024 && index < units.length - 1) {
    formattedSize /= 1024;
    index++;
  }

  return formattedSize.toFixed(2) + " " + units[index];
}

export function arraysAreEqual<T>(arr1: T[], arr2: T[]): boolean {
  if (arr1.length !== arr2.length) return false;

  return arr1.every((val, index) => val === arr2[index]);
}

export const textSearchValid = (
  first: string,
  search: string,
  start: string
) => {
  let fullName = first;
  const regex = new RegExp(start === "0" ? `^${search}` : `${search}`, "i");
  if (regex.test(fullName)) {
    return true;
  }
  return false;
};

export const getErrorMessage = (data: any) => {
  if (data.errors) {
    const errors = data.errors;
    if (Array.isArray(errors)) {
      const messages = errors.map((error: string) => error.replace(/_/g, " "));
      return messages.length > 0
        ? `${capitalizeFirstLetter(messages[0])}`
        : String(L.translate("GlobalErrors.APISomethingWrong"));
      //return messages.join("\n");
    } else {
      const values = Object.values(errors) as any[];
      const messages = values.map((eachValue: string[]) =>
        eachValue[0].replace(/_/g, " ")
      );
      return messages.length > 0
        ? `${capitalizeFirstLetter(messages[0])}`
        : String(L.translate("GlobalErrors.APISomethingWrong"));
    }
  } else {
    return `${data.message}`;
  }
};

export const makeNumberWithFloatingDigits = (
  value: string | number,
  digits: number = 8
) => {
  if (Number(value)) {
    return Number(value).toLocaleString("en", {
      minimumFractionDigits: digits,
    });
  } else {
    return value;
  }
};

export const makeAmountForXML = (value: string | number) => {
  if (Number(value)) {
    return Number(value).toFixed(2);
  } else {
    return `${value}`;
  }
};

export const isValidEmail = (email: string) => {
  return /\S+@\S+\.\S+/.test(email);
};

export const generateCSV = (
  data: [{ user: User; profile?: CollaboratorProfile }]
) => {
  console.log(data);
};

export const calculateDistance = (
  lat1: number,
  lng1: number,
  lat2: number,
  lng2: number
): number => {
  const R = 6371; // Earth's radius in kilometers
  const phi1 = (lat1 * Math.PI) / 180;
  const phi2 = (lat2 * Math.PI) / 180;
  const deltaPhi = ((lat2 - lat1) * Math.PI) / 180;
  const deltaLambda = ((lng2 - lng1) * Math.PI) / 180;

  const a =
    Math.sin(deltaPhi / 2) * Math.sin(deltaPhi / 2) +
    Math.cos(phi1) *
      Math.cos(phi2) *
      Math.sin(deltaLambda / 2) *
      Math.sin(deltaLambda / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c;
};

export const detectContinuousDates = (dateStrings: string[]) => {
  if (dateStrings.length < 2) {
    return true;
  }

  const dates = dateStrings.map((dateString) => {
    const date = parse(dateString, "yyyy-MM-dd", new Date());
    if (!isValid(date)) {
      return null;
    }
    return date;
  });

  if (dates.includes(null)) {
    return false;
  }

  dates.sort((a, b) => a!.getTime() - b!.getTime());

  for (let i = 1; i < dates.length; i++) {
    const diff = dates[i]!.getTime() - dates[i - 1]!.getTime();
    if (diff !== 24 * 3600 * 1000) {
      return false;
    }
  }

  return true;
};

export const calculateEventDuration = (
  strStartDate?: string,
  strEndDate?: string
) => {
  if (strStartDate && strEndDate) {
    const startDate = moment(strStartDate);
    const endDate = moment(strEndDate);
    if (strStartDate == strEndDate) {
      return 1;
    } else {
      return endDate.diff(startDate, "days") + 1;
    }
  } else return 0;
};

export const getEventDurationString = (
  language: string,
  strStartDate?: string,
  strEndDate?: string,
  dates?: string[]
) => {
  if (strStartDate && strEndDate) {
    const startDate = moment(strStartDate);
    const endDate = moment(strEndDate);

    if (strStartDate == strEndDate) {
      return `${startDate
        .locale(language.toLowerCase())
        .format("DD MMMM, yyyy")} (1 ${L.translate("Base.day")})`;
    } else {
      const days = endDate.diff(startDate, "days") + 1;
      let dateFormat = "Do MMM yyyy";
      if (startDate.format("yyyy") == endDate.format("yyyy")) {
        dateFormat = "Do MMM";

        if (startDate.format("MMM") == endDate.format("MMM")) {
          dateFormat = "Do";
        }
      }

      return `${startDate
        .locale(language.toLowerCase())
        .format("DD MMMM")} ~ ${endDate
        .locale(language.toLowerCase())
        .format("DD MMMM, yyyy")} (${/*days*/ dates?.length ?? 0} ${L.translate(
        "Base.days"
      )})`;

      /*
      return `${startDate
        .locale(language.toLowerCase())
        .format("Do MMM yyyy")} ~ ${endDate
        .locale(language.toLowerCase())
        .format(dateFormat)} (${days} ${L.translate("Base.days")})`;
        */
    }
  } else {
    return "-";
  }
};

export const getColorByRate = (rate: number | undefined) => {
  if (rate) {
    if (rate >= 1 && rate <= 2) {
      return "bg-warning hover:!bg-warning-100";
    } else if (rate >= 3 && rate <= 4) {
      return "bg-success hover:!bg-success-100";
    } else {
      return "bg-yellow-400 hover:!bg-yellow-100";
    }
  } else {
    return "bg-gray-modern-500 hover:!bg-gray-100";
  }
};

export const generateRandomString = (length: number = 8) => {
  const chars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  let result = "";
  for (let i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length));
  }

  return result;
};

export const makeYearSelection = () => {
  const currentYear = new Date().getFullYear();
  const start = currentYear - 10;

  const end = currentYear + 10;
  return Array.from({ length: end - start + 1 }, (_, index) => ({
    id: index,
    name: start + index,
  }));
};

export const addPlusIntoPhoneNumber = (phoneNumber: string) => {
  if (phoneNumber.length > 0) {
    if (phoneNumber == "+") {
      return "-";
    }

    if (phoneNumber.startsWith("+")) {
      return phoneNumber;
    } else {
      return `+${phoneNumber}`;
    }
  } else {
    return "-";
  }
};

export const extractIDs = (selectedItems: { [key: number]: number[] }) => {
  let arrIDs: number[] = [];
  Object.entries(selectedItems).forEach(([key, values]) => {
    if (values.length > 0) arrIDs = [...arrIDs, ...values];
  });

  return arrIDs;
};
export const extractcollabIDs = (selectedItems: Record<string, Record<string, number[]>>) => {
  let arrIDs: number[] = [];
  Object.values(selectedItems).forEach((collabGroups) => {
    Object.values(collabGroups).forEach((ids) => {
      arrIDs = [...arrIDs, ...ids];
    });
  });

  return arrIDs;
};
export const sortEventDates = (eventDates: string[], realDates: string[]) => {
  const updatedDates = eventDates
    .filter((item) => realDates.includes(item))
    .sort((a, b) => a.localeCompare(b));
  return updatedDates;
};

export const makeEventDatesString = (eventDates: string[]) => {
  if (eventDates.length == 0) {
    return "";
  }

  if (eventDates.length == 1) {
    return makeFormattedDateString(eventDates[0]);
  } else {
    let strDates = eventDates
      .map((item) => makeFormattedDateString(item))
      .join(", ");

    eventDates.forEach((item) => {
      const strMonthYear = moment(item).format("MM/yyyy");
      if (strDates.split(`/${strMonthYear}`).length - 1 > 1) {
        strDates = strDates.replace(`/${strMonthYear}`, "");
      }
    });

    return strDates;
  }
};

export const makeDefaultHTML = (
  templateHTML: string,
  event_place: string,
  event_start_date: string,
  event_end_date: string,
  event_dates: string,
  comp: number,
  language: string,
  name: string,
  link: string
) => {
  let finalHTML = templateHTML.replace("[name]", name);
  finalHTML = finalHTML.replace("[link]", link);
  finalHTML = finalHTML.replace("[event_place]", event_place);
  finalHTML = finalHTML.replace(
    "[event_start_date]",
    makeFormattedDateString(event_start_date)
  );
  finalHTML = finalHTML.replace(
    "[event_end_date]",
    makeFormattedDateString(event_end_date)
  );
  finalHTML = finalHTML.replace("[event_dates]", event_dates);
  finalHTML = finalHTML.replace(
    "[comp]",
    `${makeNumberWithFloatingDigits(comp, 2)}`
  );

  finalHTML += `<br/><table><tr><td>${EmailFooter}</td></tr></table>`;

  return finalHTML;
};

export const makeDefaultHTMLLocal = (
  event_place: string,
  event_start_date: string,
  event_end_date: string,
  comp: number,
  language: string,
  name: string,
  link: string
) => {
  return `
    <p>Ciao ${name}</p>
    <p>in allegato troverai il CONTRATTO per l'attività lavorativa in oggetto.</p>
    <p>Ti chiedo gentilmente di firmare il contratto e di inviarlo, entro e non oltre il 7 AGOSTO , a questo link:</p>
    <a href='${link}'>Clicca Qui</a>
    <p>Non è necessario stamparlo, basterà utilizzare l’app ADOBE FILL & SIGN sul tuo cellulare.</p>
    <p>E’ importantissimo comunicarci il numero della propria carta d’identità per poter effettuare la comunicazione della propria prestazione lavorativa all’INPS.</p>
    <p>A seguire le info:</p>
    <p>Luogo   ${event_place}</p>
    <p>Giorno ed Orari di lavoro: ${makeFormattedDateString(
      event_start_date
    )} ~ ${makeFormattedDateString(event_end_date)}</p>
    <p>(si raccomanda puntualità e massima flessibilità. Eventuali ore extra saranno pagate);</p>
    <p>Mansione: accoglienza, gestione flussi, gestione merchandiser, informazione, vendita con pos, supporto cliente per qualsiasi necessità ;</p>
    <p>Abbigliamento: Di proprietà pantaloni neri, sneakers bianche. Polo e cappellino forniti</p>
    <p>Compenso: € ${makeNumberWithFloatingDigits(comp, 2)} nette</p>
    <p>Modalità di pagamento: il bonifico sarà effettuato a 30 gg fm entro il 12 del mese successivo.</p>
    <p>Entro la scadenza riceverai una mail da ritenute@wyagency.it <mailto:ritenute@wyagency.it> con modulo ritenuta d’acconto da firmare per presa visione della correttezza dei dati e compensi da percepire. Sulla ritenuta d’acconto dovrai apporre la marca da bollo pari ad euro 2,00 se l’importo è superiore ai 77,47 euro lordi. </p>
    <p>Norme comportamentali: Si suggerisce di essere sempre professionale, disponibile, sorridente al pubblico e cortese, dunque di svolgere al meglio il proprio lavoro. Si richiede di evitare unghie lunghissime, smalti fluo o con colori troppo accesi (OK gradazioni di rosso, nero – se con unghie corte e curate – latte, trasparente). Si raccomanda di avere un aspetto curato, capelli puliti e ordinati. Trucco presente ed elegante, non volgare.</p>
    <p>Non sono ammessi orecchini, collane, anelli particolarmente vistosi. No ad occhiali da vista e piercing sul viso.</p>
    <p>E’ vietato:</p>
    <p>1. Uso del telefonino durante l’evento (se non autorizzato dal referente With You Agency)</p>
    <p>2. Atteggiamenti non consoni al contesto (es. fumare|mangiare in pubblico, allontanarsi dalla postazione lavorativa senza preavviso ecc.)</p>
    <p>Non disponiamo di pass parcheggio, quindi ti suggeriamo di organizzarti autonomamente con i mezzi pubblici o con parcheggi limitrofi alla location non a pagamento.</p>
    <p>I pasti non sono previsti dal cliente</p>
    <p>Per qualsiasi dubbio siamo a disposizione.</p>
    <p>NB. Ultimamente stanno purtroppo capitando episodi molto spiacevoli di rinuncia al lavoro a pochi giorni o a poche ore dall’evento/manifestazione – potete immaginare quanto spiacevole possa essere questa situazione e le conseguenti difficoltà nel reperire all’ultima una risorsa– Vi preghiamo di prendere seriamente l’impegno del lavoro e nel caso di rinuncia per motivi seri di provvedere a trovare un/a sostituto/a all’altezza.</p>
    <p>Grazie per la collaborazione,</p><br/>
    <p>Staff With You</p><br/>
    <table>
    <tr>
    <td>${EmailFooter}</td>
    </tr>
    </table>
    
  `;
};

export const makeFormattedDateString = (
  dateStr: string,
  showsTime: boolean = false
) => {
  return moment(dateStr).format(
    showsTime ? "DD/MM/yyyy hh:mm a" : "DD/MM/yyyy"
  );
};

export const getDateStrings = (startDateStr: string, endDateStr: string) => {
  // Parse the start and end dates
  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);

  // Check if the dates are valid
  if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
    return [];
  }

  // Create an array to hold the date strings
  const dateStrings: string[] = [];

  // Loop through each date from start to end
  for (let currentDate = startDate; currentDate <= endDate; ) {
    // Format the current date to YYYY-MM-DD and add to the array
    const formattedDate = currentDate.toISOString().split("T")[0];
    dateStrings.push(formattedDate);

    // Increment the date by one day
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dateStrings;
};

export const makePermissionList = (strPermissions: string, lang: string) => {
  const items = strPermissions.split(",");

  const updatedItems = items.map((item) => {
    const nIdxEN = Permissions.findIndex(
      (eachPermission) => eachPermission == item
    );
    const nIdxIT = Permissions_IT.findIndex(
      (eachPermission) => eachPermission == item
    );

    if (lang == "EN") {
      if (nIdxEN >= 0) {
        return Permissions[nIdxEN];
      } else {
        return Permissions[nIdxIT];
      }
    } else {
      if (nIdxIT >= 0) {
        return Permissions_IT[nIdxIT];
      } else {
        return Permissions_IT[nIdxEN];
      }
    }
  });

  return updatedItems.join(",");
};

export const getPermittedFeatures = (strPermissions: string) => {
  if (strPermissions.length == 0) return [];

  const menus = [];
  if (
    strPermissions.includes("Clients") ||
    strPermissions.includes("Clienti")
  ) {
    menus.push({
      link: routes.clients,
      icon: <UserIcon className="h-[20px] w-[20px] text-primary" />,
      activeIcon: <UserIcon className="h-[20px] w-[20px] text-white" />,
      title: String(L.translate("Sidebar.clients")),
    });
  }

  if (
    strPermissions.includes("Collaborators") ||
    strPermissions.includes("Collaboratori")
  ) {
    menus.push({
      link: routes.collaborators,
      icon: <AddressBookIcon className="h-[20px] w-[20px] text-primary" />,
      activeIcon: <AddressBookIcon className="h-[20px] w-[20px] text-white" />,
      title: String(L.translate("Sidebar.collaborators")),
    });
  }

  if (strPermissions.includes("Events") || strPermissions.includes("Eventi")) {
    menus.push({
      link: routes.events,
      icon: <CalendarStarIcon className="h-[20px] w-[20px] text-primary" />,
      activeIcon: <CalendarStarIcon className="h-[20px] w-[20px] text-white" />,
      title: String(L.translate("Sidebar.events")),
    });
  }

  if (
    strPermissions.includes("Job Ads") ||
    strPermissions.includes("Annunci")
  ) {
    menus.push({
      link: routes.jobAds,
      icon: <WhatsAppIcon className="h-[20px] w-[20px] text-primary" />,
      activeIcon: <WhatsAppIcon className="h-[20px] w-[20px] text-white" />,
      title: String(L.translate("Sidebar.job_ads")),
    });
  }

  if (
    strPermissions.includes("Plan job") ||
    strPermissions.includes("Pianifica")
  ) {
    menus.push({
      link: routes.planJob,
      icon: <ListCheckIcon className="h-[20px] w-[20px] text-primary" />,
      activeIcon: <ListCheckIcon className="h-[20px] w-[20px] text-white" />,
      title: String(L.translate("Sidebar.plan_job")),
    });
  }

  if (
    strPermissions.includes("Calendar") ||
    strPermissions.includes("Calendario")
  ) {
    menus.push({
      link: routes.calendar,
      icon: <CalendarIcon className="h-[20px] w-[20px] text-primary" />,
      activeIcon: <CalendarIcon className="h-[20px] w-[20px] text-white" />,
      title: String(L.translate("Sidebar.calendar")),
    });
  }

  if (strPermissions.includes("Casting")) {
    menus.push({
      link: routes.casting,
      icon: <AssignIcon className="h-[20px] w-[20px] text-primary" />,
      activeIcon: <AssignIcon className="h-[20px] w-[20px] text-white" />,
      title: String(L.translate("Sidebar.casting")),
    });
  }

  if (
    strPermissions.includes("Payments") ||
    strPermissions.includes("Pagamenti")
  ) {
    menus.push({
      link: routes.payments,
      icon: <WalletIcon className="h-[20px] w-[20px] text-primary" />,
      activeIcon: <WalletIcon className="h-[20px] w-[20px] text-white" />,
      title: String(L.translate("Sidebar.payments")),
    });
  }

  if (
    strPermissions.includes("Invoices") ||
    strPermissions.includes("Fatture")
  ) {
    menus.push({
      link: routes.invoices,
      icon: <ReceiptIcon className="h-[20px] w-[20px] text-primary" />,
      activeIcon: <ReceiptIcon className="h-[20px] w-[20px] text-white" />,
      title: String(L.translate("Sidebar.invoices")),
    });
  }

  if (
    strPermissions.includes("Report") ||
    strPermissions.includes("Rapporto")
  ) {
    menus.push({
      link: routes.reports,
      icon: <DocumentIcon className="h-[20px] w-[20px] text-primary" />,
      activeIcon: <DocumentIcon className="h-[20px] w-[20px] text-white" />,
      title: String(L.translate("Sidebar.report")),
    });
  }

  if (
    strPermissions.includes("Profile") ||
    strPermissions.includes("Profilo")
  ) {
    menus.push({
      link: routes.profile,
      icon: <UserIcon className="h-[20px] w-[20px] text-primary" />,
      activeIcon: <UserIcon className="h-[20px] w-[20px] text-white" />,
      title: String(L.translate("Sidebar.profile")),
    });
  }

  return menus;
};
