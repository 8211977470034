import moment from "moment";
import { FC, useEffect, useState } from "react";
import { Event } from "src/api/types";
import { CollaboratorBidType, PaymentDataType } from "src/lib/data-types";
import {
  capitalizeFirstLetter,
  makeNumberWithFloatingDigits,
} from "src/lib/utils";
import { format } from "date-fns";
import { it } from "date-fns/locale/it";

type PaymentPDFDownloadProps = {
  data: PaymentDataType[];
};

const PaymentPDFDownload: FC<PaymentPDFDownloadProps> = ({ data }) => {
    // const [city, setCity] = useState("");
    // const [postalCode, setPostalCode] = useState("");
    // const [address, setAddress] = useState("");
    // const [pronvice, setProvince] = useState("");

    // const jobDate = moment(data.event_start_date).toDate();
    // const jobYear = format(jobDate, "yyyy", { locale: it });
    // const jobMonth = format(jobDate, "MMMM", { locale: it });
    // const jobDay = data.dates
    //   .map((eachDay) => {
    //     const eachDate = moment(eachDay).toDate();
    //     return format(eachDate, "d", { locale: it });
    //   })
    //   .join(", ");

    // const compAmount = (Number(data.amount) ?? 0) / 0.8;
    // const fee = compAmount * 0.2;
    // const netAmount = Number(data.amount) ?? 0;
    // const reimburse = Number(data.reimburse) ?? 0;
    // const totalAmount = netAmount + reimburse;

    // useEffect(() => {
    //   const items = data.collab_info.residence_address.split("$$$");
    //   if (items.length < 4) {
    //     setCity("");
    //     setPostalCode("");
    //     setProvince("");
    //     setAddress("");
    //   } else {
    //     setAddress(items[0]);
    //     setCity(items[1]);
    //     setProvince(items[2]);
    //     setPostalCode(items[3]);
    //   }
    // }, [data]);
    const [city, setCity] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [address, setAddress] = useState("");
    const [province, setProvince] = useState("");
    const [collab_info, setCollabinfo] = useState(Object);
  
    // Aggregate amounts
    const totalComp = data.reduce((sum, item) => sum + Number(item.amount || 0), 0)/ 0.8;
    const totalFee = totalComp * 0.2;
    const totalNetAmount = data.reduce((sum, item) => sum + Number(item.amount || 0), 0);
    const totalReimburse = data.reduce((sum, item) => sum + Number(item.reimburse || 0), 0);
    const totalAmount = totalNetAmount + totalReimburse;
  
    // Aggregate job dates
    const jobDates = data.flatMap((item) => item.dates.map((date) => moment(date).toDate()));
    const jobYears = [...new Set(jobDates.map((date) => format(date, "yyyy", { locale: it })))].join(", ");
    const jobMonths = [...new Set(jobDates.map((date) => format(date, "MMMM", { locale: it })))].join(", ");
    const jobDays = jobDates.map((date) => parseInt(format(date, "d", { locale: it }), 10));
  
    useEffect(() => {
      // Assume all items have the same residence address since the collaborator is the same
      if (data.length > 0) {
        setCollabinfo(data[0].collab_info);
        const items = data[0].collab_info.residence_address.split("$$$");
        if (items.length < 4) {
          setCity("");
          setPostalCode("");
          setProvince("");
          setAddress("");
        } else {
          setAddress(items[0]);
          setCity(items[1]);
          setProvince(items[2]);
          setPostalCode(items[3]);
         
        }
      }
    }, [data]);

  return (
    <div className="k-pdf-export w-full flex flex-col px-12 pt-6 pb-6">
      <p className="mt-4 font-gilroy-bold text-[12px] text-primary text-left">
        {`${capitalizeFirstLetter(
          collab_info.last_name ?? ""
        )} ${capitalizeFirstLetter(collab_info.first_name ?? "")}`}
      </p>
      <p className="mt-4 font-gilroy-bold text-[12px] text-primary text-left">
        {address}
      </p>
      <p className="mt-4 font-gilroy-bold text-[12px] text-primary text-left">
        {`${postalCode} ${city} ${province}`}
      </p>
      <div className="mt-4 flex flex-row items-center justify-start gap-4">
        <p className="font-gilroy text-[12px] text-primary text-left">
          Data di nascita
        </p>
        <p className="font-gilroy-bold text-[12px] text-primary text-left">
          {moment(collab_info.date_of_birth).format("DD/MM/yyyy")}
        </p>
      </div>
      <div className="flex flex-row items-center justify-start gap-4">
        <p className="font-gilroy text-[12px] text-primary text-left">
          Luogo di nascita
        </p>
        <p className="font-gilroy-bold text-[12px] text-primary text-left">
          {collab_info.birth_place}
        </p>
      </div>
      <div className="flex flex-row items-center justify-start gap-4">
        <p className="font-gilroy text-[12px] text-primary text-left">
          Codice fiscale
        </p>
        <p className="font-gilroy-bold text-[12px] text-primary text-left">
          {collab_info.tax_id_code}
        </p>
      </div>
      <div className="flex flex-row items-center justify-start gap-4">
        <p className="font-gilroy text-[12px] text-primary text-left">IBAN</p>
        <p className="font-gilroy-bold text-[12px] text-primary text-left">
          {collab_info.IBAN}
        </p>
      </div>
      <div className="flex flex-row items-center justify-start gap-4">
        <p className="font-gilroy text-[12px] text-primary text-left">Intestatario IBAN</p>
        <p className="font-gilroy-bold text-[12px] text-primary text-left">
          {collab_info.current_account_holder}
        </p>
      </div>
      <div className="mt-4 flex flex-row items-center justify-center w-full">
        <div className="w-full" />
        <div className="w-full flex flex-col items-start">
          <p className="font-gilroy text-[12px] text-primary text-left">
            Spett.le
          </p>
          <p className="font-gilroy-bold text-[12px] text-primary text-left">
            WITH YOU s.r.l.
          </p>
          <p className="font-gilroy-bold text-[12px] text-primary text-left">
            Via Privata del Gonfalone, 3
          </p>
          <p className="font-gilroy-bold text-[12px] text-primary text-left">
            20123 Milano (MI)
          </p>
          <p className="font-gilroy text-[12px] text-primary text-left">
            P.Iva
            <span className="ml-4 font-gilroy-bold text-[12px] text-primary text-left">
              10940150963
            </span>
          </p>
        </div>
      </div>
      <p className="mt-4 font-gilroy text-[12px] text-primary text-left">
        {`Compenso per le mie prestazioni nel settore dei servizi Promo -
        Pubblicitari, effettuati per Vostro conto nell'anno ${jobYears}, nel mese di ${jobMonths}, nei giorni: ${jobDays}`}
      </p>
      <div className="mt-4 flex flex-col items-end ml-6 mr-12">
        <div className="mt-2 flex flex-row items-center justify-end w-full">
          <p className="w-full font-gilroy-bold text-[12px] text-primary text-right">
            Compenso lordo
          </p>
          <p className="w-[80px] flex-shrink-0 font-gilroy-bold text-[12px] text-primary text-right">
            €
          </p>
          <p className="w-[120px] flex-shrink-0 font-gilroy-bold text-[12px] text-primary text-right">
            {makeNumberWithFloatingDigits(totalComp, 2)}
          </p>
        </div>
        <div className="mt-2 flex flex-row items-center justify-end w-full">
          <p className="w-full font-gilroy-bold text-[12px] text-primary text-right">
            Ritenuta d'Acconto
          </p>
          <p className="w-[80px] flex-shrink-0 font-gilroy-bold text-[12px] text-primary text-right">
            €
          </p>
          <p className="w-[120px] flex-shrink-0 font-gilroy-bold text-[12px] text-primary text-right">
            {makeNumberWithFloatingDigits(totalFee, 2)}
          </p>
        </div>
        <div className="mt-2 bg-primary rounded-[2px] w-[140px] h-[2px]" />
        <div className="mt-2 flex flex-row items-center justify-end w-full">
          <p className="w-full font-gilroy-bold text-[12px] text-primary text-right">
            Compenso Netto da pagare
          </p>
          <p className="w-[80px] flex-shrink-0 font-gilroy-bold text-[12px] text-primary text-right">
            €
          </p>
          <p className="w-[120px] flex-shrink-0 font-gilroy-bold text-[12px] text-primary text-right">
            {makeNumberWithFloatingDigits(totalNetAmount, 2)}
          </p>
        </div>
        <div className="mt-2 flex flex-row items-center justify-end w-full">
          <p className="w-full font-gilroy-bold text-[12px] text-primary text-right">
            Rimborso spese{" "}
            <span className="font-gilroy text-[12px] text-primary">
              (esente)
            </span>
          </p>
          <p className="w-[80px] flex-shrink-0 font-gilroy-bold text-[12px] text-primary text-right">
            €
          </p>
          <p className="w-[120px] flex-shrink-0 font-gilroy-bold text-[12px] text-primary text-right">
            {makeNumberWithFloatingDigits(totalReimburse, 2)}
          </p>
        </div>
        <div className="mt-2 bg-primary rounded-[2px] w-[140px] h-[2px]" />
        <div className="mt-2 flex flex-row items-center justify-end w-full">
          <p className="w-full font-gilroy-bold text-[12px] text-primary text-right">
            Totale
          </p>
          <p className="w-[80px] flex-shrink-0 font-gilroy-bold text-[12px] text-primary text-right">
            €
          </p>
          <p className="w-[120px] flex-shrink-0 font-gilroy-bold text-[12px] text-primary text-right">
            {makeNumberWithFloatingDigits(totalAmount, 2)}
          </p>
        </div>
      </div>
      <p className="mt-2 font-gilroy-italic text-[10px] text-primary text-left">
        Il sottoscritto dichiara che
      </p>
      <p className="mt-2 font-gilroy-italic text-[10px] text-primary text-left">
        - il corrispettivo di cui sopra non è soggetto ad IVA ai sensi dell'art.
        5 del DPR n. 633 del 26/10/72, modificato per effetto del DPR 29/01/79
        in quanto trattasi di prestazione resa da soggetto che non esercita per
        professione abituale altre attività di lavoro autonomo.
      </p>
      <p className="mt-2 font-gilroy-italic text-[10px] text-primary text-left">
        - il corrispettivo di cui sopra è soggetto a ritenuta di acconto del 20%
        ai sensi e per effetti dell'art. 25 del DPR n. 600 29/09/73 e successive
        modificazioni.
      </p>
      <p className="mt-2 font-gilroy-italic text-[10px] text-primary text-left">
        - ai fini dell’assoggettamento degli emolumenti alla disciplina
        previdenziale non ho raggiunto nel corso dell’anno solare il limite di
        €. 5.000,00 e dei 30 giorni lavorativi (rif. art. 61 D.Lgs. 276/03).
      </p>
      <p className="mt-2 font-gilroy-italic text-[10px] text-primary text-left">
        - in caso di superamento di € 77,47 lordi applicare marca da bollo di €
        2,00 sull'originale ai sensi dell'art. 25 del d.p.r.642/1972.
      </p>
      <div className="mt-4 flex flex-row items-start justify-between">
        <div className="mt-4 flex flex-row items-end gap-4">
          <p className="font-gilroy-semibold text-[12px] text-primary text-left">
            {`Data ${moment().format("DD/MM/yyyy")}`}   { `Luogo`}
          </p>
          <div className="w-[160px] h-[36px] border-b-[1px] border-primary" />
        </div>
        <div className="flex flex-col items-start justify-center">
          <p className="font-gilroy-semibold text-[12px] text-primary text-left">
            In fede
          </p>
        </div>
      </div>
    </div>
  );
};

export default PaymentPDFDownload;
