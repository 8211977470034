import { forwardRef, useEffect, useState } from "react";
import classNames from "classnames";
import { CrossIcon } from "../icons/cross-icon";
import Select from "react-select";
import countryData from './country_data.json'
import React from "react";

type InputPhoneNumberProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  label?: string;
  error?: string;
  className?: string;
  inputClassName?: string;
  required?: boolean;
  showInfo?: boolean;
  info?: string;
  showClose?: boolean;
  allCaps?: boolean;
  value: string;
  onChange: any;
  clickedClose?: any;
};

const InputPhoneNumber = forwardRef<HTMLInputElement, InputPhoneNumberProps>(
  (
    {
      label,
      error,
      type = "text",
      className,
      inputClassName = "bg-gray-modern-100",
      required,
      showInfo = false,
      showClose = false,
      allCaps = false,
      clickedClose,
      info,
      onChange,
      value,
      ...props
    },
    ref
  ) => {
    type CountryOption = {
      value: string;
      label: JSX.Element;
    };
    const [number, setNumber] = useState(""); // Local state for the number
    const [countryOptions, setCountryOptions] = useState<CountryOption[]>([]);
    const [selectedCountry, setSelectedCountry] = useState<CountryOption | null>(null); // Make it nullable
    const [countryCode, setCountryCode] = useState('');

    useEffect(() => {
      // Fetch countries for the dropdown
      const fetchCountries = async () => {
        // const response = await fetch("country_data.json");
        // console.log(response, "RESPONSE")
        // const countries = await response.json();
        // console.log(countries, "COUNTRY")

        const options = countryData.map((country: any) => ({
          value: country.idd?.root + (country.idd?.suffixes?.[0] || ""),
          label: (
            <div className="flex flex-row">
              <img
                src={country.flags.svg}
                alt={country.name.common}
                style={{ width: "40px",  height: "30px", marginRight: "10px" }}
              />
              {`${country.name.common} (${country.idd?.root}${country.idd?.suffixes?.[0] || ""})`}
            </div>
          ),
        }));

        setCountryOptions(options);

        // Set default selected country
        if (options.length > 0) {
          setSelectedCountry(options[11]);
          setCountryCode(options[11].value);
        }
      };

      fetchCountries();
    }, []);
    
    useEffect(() => {
      if (value) {
        console.log(value, "VALUE")
        // Check if value starts with a valid country code
        console.log(countryOptions, "options")
        const countryMatch = countryOptions.find((option) =>
          value.startsWith("+")
        );
  
        if (countryMatch) {
          console.log(countryMatch, "MATCH")
          // Value includes a country code
          setSelectedCountry(countryOptions[0]);
          setCountryCode(countryOptions[0].value);
          setNumber(value.substring(3).trim()); // Remove country code from number
        } else {
          // Value does not include a country code
          if (countryOptions.length > 0) {
            const defaultCountry = countryOptions[0];
            console.log(defaultCountry);
            setSelectedCountry(defaultCountry);
            setCountryCode(defaultCountry.value);
            setNumber(value.trim()); // Treat entire value as the number
          }
        }
      } else {
        // For new entries, set default country code
        if (countryOptions.length > 0) {
          const defaultCountry = countryOptions[0];
          console.log(defaultCountry, "default");
          setSelectedCountry(defaultCountry);
          setCountryCode(defaultCountry.value);
          setNumber(""); // Empty input for new entry
        }
      }
    }, [value, countryOptions]);
  
    const onTextChange = (e:any) => {
      const inputValue = e.target.value;
      const regex = /^[0-9]{0,15}$/; // Allow only numbers up to 15 digits
  
      if (regex.test(inputValue)) {
        setNumber(inputValue); // Only update the number part
        console.log(countryCode, "CODE")
        const newValue = `${countryCode}${inputValue}`; // Combine with country code
        onChange(newValue); // Pass combined value to parent
      }
    };
  
    const handleCountryChange = (selectedOption:any) => {
      setSelectedCountry(selectedOption);
      setCountryCode(selectedOption.value);
      const newValue = `${selectedOption.value}${number}`; // Combine new country code with existing number
      onChange(newValue); // Pass combined value to parent
    };
  

    return (
      <div className={className}>
        <label className="block text-16px font-gilroy font-medium text-secondary relative">
          {label && (
            <span className="block cursor-pointer pb-2 text-[14px] font-gilroy font-medium text-secondary dark:text-light/70">
              {required && (
                <span className="text-[14px] text-error font-gilroy">* </span>
              )}
              {label}
            </span>
          )}
          <div className="flex flex-col items-center gap-2 sm:flex-row sm:items-start">
          <Select
          options={countryOptions}
          value={selectedCountry}
          onChange={handleCountryChange}
          placeholder="Select a country" // Default placeholder
          isSearchable
          menuPortalTarget={document.body}
          filterOption={(option, searchText) => {
            let labelText = "";

            if (typeof option.label === "string") {
              // Handle string labels
              labelText = option.label.toLowerCase();
            } else if (React.isValidElement(option.label)) {
              // Assert the label as a JSX.Element
              const labelElement = option.label as React.ReactElement;
              const children = labelElement.props.children;

              if (Array.isArray(children)) {
                labelText = children
                  .filter((child) => typeof child === "string")
                  .join("")
                  .toLowerCase();
              } else if (typeof children === "string") {
                labelText = children.toLowerCase();
              }
            }

            const valueText = option.value.toLowerCase(); // Country code
            return (
              labelText.includes(searchText.toLowerCase()) ||
              valueText.includes(searchText.toLowerCase())
            );
          }}
          styles={{
            control: (base, state) => ({
              ...base,
              minWidth: "180px",
              borderColor: state.isFocused ? "#3b82f6" : "#d1d5db", // Tailwind blue-500 for focus
              boxShadow: state.isFocused ? "0 0 0 1px #3b82f6" : "",
              "&:hover": { borderColor: "#3b82f6" },
              backgroundColor: "white",
            }),
            singleValue: (base, state) => ({
              ...base,
              display: state.selectProps.menuIsOpen ? "none" : "block", // Hide value when menu is open
            }),
            placeholder: (base, state) => ({
              ...base,
              display: state.selectProps.menuIsOpen ? "none" : "block", // Hide placeholder when menu is open
            }),
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            option: (base, state) => ({
              ...base,
              backgroundColor: state.isFocused ? "#e5f3ff" : "white", // Tailwind blue-100 for hover
              color: state.isFocused ? "#1d4ed8" : "black",
            }),
          }}
        />

            <input
              type={type}
              ref={ref}
              value={number}
              onChange={onTextChange}
              {...props}
              className={classNames(
                "h-[44px] placeholder-gray-modern-400 border-none w-full appearance-none text-[14px] font-gilroy font-medium rounded-[8px] px-4 py-2 text-primary ring-0 ring-bg-gray-modern-100 focus:ring-[0.5px] lg:px-5 ml-3",
                showClose && classNames("!pr-10"),
                allCaps && "uppercase",
                inputClassName
              )}
            />
          </div>

          {showClose && (
            <button
              className="absolute bottom-0 right-0 h-[44px] w-[44px] flex items-center justify-center"
              onClick={() => clickedClose && clickedClose()}
            >
              <CrossIcon className="w-3 h-3 text-secondary" />
            </button>
          )}
        </label>
        {showInfo && (
          <span
            role="info"
            className="block pt-2 text-[12px] text-secondary font-gilroy font-medium"
          >
            {info}
          </span>
        )}
        {error && (
          <span
            role="alert"
            className="block pt-2 text-[12px] text-error font-gilroy"
          >
            {`* ${error}`}
          </span>
        )}
      </div>
    );
  }
);

InputPhoneNumber.displayName = "InputPhoneNumber";
export default InputPhoneNumber;