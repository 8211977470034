export enum UserType {
  COLLAB = "collab",
  BASIC_ADMIN = "basic",
  SUPER_ADMIN = "super",
}

export interface Photo {
  id: number;
  url: string;
}

export interface User {
  id: number;
  email: string;
  last_login: number;
  status: number;
  is_active: boolean;
  is_staff: boolean;
  is_superuser: boolean;
  cut?: number;
  height?: number;
  created_at: string;
  updated_at: string;
  first_name?: string;
  last_name?: string;
  location: string;
  avatar?: string;
  user_type: UserType;
  permissions: string;
  rate: number;
  feedback: string;
  confirmed?: boolean;
  collaborator?: { [key: string]: any };
}

export interface UserResponse {
  success: boolean;
  token?: string;
  refresh?: string;
  user: User;
}

export interface UsersResponse {
  success: boolean;
  users: User[];
}

export interface SearchQueryOptions {
  collaborator?: boolean;
  page: number;
  per_page: number;
  sort: string;
  direction: string;
  user_name?: string;
  event_name?: string;
  event_date?: string;
  event_start_date?: string;
  event_end_date?: string;
  client_name?: string;
  payment_date?: string;
  bid_status?: number;
  status?: number;
  account?: number;
  all?:string;
}
export interface CollabProfileQueryOptions {
  all?:string;
}

export interface CollabSearchQueryOptions {
  page: number;
  per_page: number;
  sort: string;
  direction: string;
  city?: string;
  height?: string;
  age?: string;
  language?: number;
}

export interface InvoiceSearchQueryOptions {
  page: number;
  per_page: number;
  sort: string;
  direction: string;
  date?: string;
  event_name?: string;
  due_date?: string;
  client_name?: string;
  status?: number;
}

export interface PaymentSearchQueryOptions {
  page: number;
  per_page: number;
  sort: string;
  direction: string;
  event_name?: string;
  date?: string;
  collab_name?: string;
  status?: number;
}
export interface PaginatorInfo<T> {
  count: number;
  next: string | null;
  previous: string | null;
  results: T[];
}

export interface UserPaginator extends PaginatorInfo<User> {}

export interface StatusResponse {
  success: boolean;
  message?: string;
}

export interface EventByIDResponse {
  success: boolean;
  message?: string;
  data: Event;
}

export interface WebCastingCodeResponse {
  success: boolean;
  message?: string;
  code?: number;
}

export interface LoginUserInput {
  email: string;
  password: string;
  lat: number;
  lng: number;
}

export interface RegisterUserInput {
  email: string;
  password?: string;
  first_name: string;
  last_name: string;
}

export interface ChangePasswordInput {
  old_pass: string;
  new_pass: string;
}

export interface LogoutInput {
  token: string;
}

export interface UpdateProfileInput {
  first_name: string;
  last_name: string;
  height?: number;
  cut?: number;
}

export interface DeletePhotoInput {
  photo_id: number;
}

export interface ClientPaginator extends PaginatorInfo<Client> {}

export interface Client {
  id: number;
  name: string;
  address: string;
  city: string;
  prov: string;
  zip: string;
  email: string;
  pec_email: string;
  telephone_number: string;
  vat_number: string;
  foreign_number: string;
  recipient_code: string;
  status: boolean;
}

export interface RegisterClientInput {
  name: string;
  address: string;
  city: string;
  prov: string;
  zip: string;
  email: string;
  pec_email: string;
  telephone_number: string;
  vat_number: string;
  foreign_number: string;
  recipient_code: string;
}

export interface UpdateClientInput {
  name: string;
  address: string;
  city: string;
  prov: string;
  zip: string;
  pec_email: string;
  telephone_number: string;
  vat_number: string;
  foreign_number: string;
  recipient_code: string;
}

export interface ClientsResponse {
  success: boolean;
  results: Client[];
}

export interface CollaboratorsResponse {
  success: boolean;
  results: User[];
}

export interface BidCompValueUpdateInput {
  bid_id: number;
  collab_id: number;
  comp?: number;
  reimburse?: number;
  event_date: string;
  payment_date: string;
  notes: string;
  is_copied_all: boolean;
  casting_fields: string;
}

export interface Event {
  id: number;
  name: string;
  description: string;
  city: string;
  account: number;
  contract: number;
  invoice_no: string;
  quote_no: string;
  invoice_descr: string;
  contract_received: number;
  number_of_collaborators: number;
  numbers_per_day: string;
  location?: string;
  coordinate?: string;
  start_date?: string;
  end_date?: string;
  dates?: string[];
  status: number;
  bid_id: number;
  bid_status: number;
  bid_comp: number;
  bid_reimburse: number;
  bid_contract_signed: number;
  bid_contract_pdf: string;
  bid_notes: string;
  bid_event_date: string;
  bid_payment_date: string;
  bids_list: string[];
  client: Client;
  created_at: string;
  updated_at: string;
}

export interface EventPaginator extends PaginatorInfo<Event> {}

export interface EventPreRegisterInput {
  name: string;
  description: string;
  contract?: number;
  invoice_no: string;
  client_id: number;
  quote_no: string;
  account: number;
  invoice_descr: string;
}

export interface EventUpdateInput {
  event_id: number;
  name: string;
  description: string;
  contract?: number;
  invoice_no: string;
  client_id: number;
  quote_no: string;
  location: string;
  coordinate: string;
}

export interface EventResponse {
  success: boolean;
  data: Event;
}

export interface EventsResponse {
  success: boolean;
  results: Event[];
}

export interface EventRegisterInput {
  event_id: number;
  location: string;
  coordinate: string;
  number_of_collaborators: number;
  numbers_per_day: string;
  date: string;
}

export interface EventUpdateDatesInput {
  event_id: number;
  number_of_collaborators: number;
  numbers_per_day: string;
  date: string;
}

export interface EventDayCount {
  day: number;
  count: number;
}

export interface EventListByDateResponse {
  success: boolean;
  data: Event[]; //EventDayCount[];
}

export interface EventDayEvents {
  [key: number]: Event[];
}

export interface EventListByWeekResponse {
  success: boolean;
  data: EventDayEvents;
}

export interface BidWithProfileInput {
  event_id: number;
  dates: string[];
}

export interface BidInput {
  event_id: number;
  notes: string;
  info: string;
  cv: any;
  cv_extension: string;
  main_photo: any;
  photo2: any;
  photo3: any;
  photo4: any;
  photo5: any;
}

export interface CollaboratorCreateProfileInput {
  first_name: string;
  last_name: string;
  password: string;
  email: string;
  info: string;
  video1?: any;
  video_extension1: string;
  video2?: any;
  video_extension2: string;
  cv: any;
  cv_extension: string;
  main_photo: any;
  photo2?: any;
  photo3?: any;
  photo4?: any;
  photo5?: any;
  photo6?: any;
  photo7?: any;
  photo8?: any;
  photo9?: any;
}

export interface CollaboratorCurrentUpdateProfileInput {
  user_id: number;
  info: string;
  video1?: any;
  video_extension1: string;
  original_video_filename1: string;
  video2?: any;
  video_extension2: string;
  original_video_filename2: string;
  cv: any;
  cv_extension: string;
  email: string;
  main_photo: any;
  photo2?: any;
  photo3?: any;
  photo4?: any;
  photo5?: any;
  photo6?: any;
  photo7?: any;
  photo8?: any;
  photo9?: any;
}

export interface CollaboratorUpdateProfileInput {
  user_id: number;
  info: string;
  cv: any;
  cv_extension: string;
  email: string;
  main_photo: any;
  video1?: any;
  video_extension1: string;
  original_video_filename1: string;
  video2?: any;
  video_extension2: string;
  original_video_filename2: string;
  photo2?: any;
  photo3?: any;
  photo4?: any;
  photo5?: any;
  photo6?: any;
  photo7?: any;
  photo8?: any;
  photo9?: any;
  orders: string[];
  current_photos: string[];
}

export interface CollaboratorProfile {
  id: number;
  confirmed: boolean;
  user: number;
  video1: string;
  video2: string;
  cv: string;
  main_photo: string;
  photo2: string;
  photo3: string;
  photo4: string;
  photo5: string;
  photo6: string;
  photo7: string;
  photo8: string;
  photo9: string;
  info: { [key: string]: any };
  location: string;
  order?: number;
  created_at?: string;
}

export interface CollaboratorEventDatesResponse {
  success: boolean;
  dates: string[];
  message?: string;
}

export interface CollaboratorProfileResponse {
  success: boolean;
  collaborator: CollaboratorProfile;
}

export interface BasicCollaboratorProfile {
  id: number;
  first_name: string;
  last_name: string;
}

export interface BasicCollaboratorProfilesResponse {
  success: boolean;
  message?: string;
  results: BasicCollaboratorProfile[];
}

export interface CollaboratorProfilesResponse {
  success: boolean;
  message?: string;
  results: CollaboratorProfile[];
}

export interface CollaboratorUpdateProfileResponse {
  success: boolean;
  user: User;
  collaborator: CollaboratorProfile;
}

export interface AmountResponse {
  success: boolean;
  amount: number;
}

export interface CollaboratorBidsPerEvent {
  id: number;
  user: User;
  created_at: string;
  updated_at: string;
  cv: string;
  main_photo: string;
  photo2: string;
  photo3: string;
  photo4: string;
  photo5: string;
  photo6: string;
  photo7: string;
  photo8: string;
  photo9: string;
  video1: string;
  video2: string;
  info: { [key: string]: any };
  comp: number;
  reimburse: number;
  notes: string;
  event?: Event;
  status: number; //bid status
  event_date: string;
  profile?: number;
  payment_date: string;
  contract_signed: number;
  contract_pdf: string;
  casting_fields: string;
  event_dates?: string[];
  total?: boolean;
  total_comp: number;
  total_reimburse: number;
}

export interface CollaboratorBidPaginator
  extends PaginatorInfo<CollaboratorBidsPerEvent> {}

export interface CollaboratorBid {
  id: number;
  event_id: number;
  event_name: string;
  start_date: string;
  end_date: string;
  payment_date: string;
  contract_pdf: string;
  contract_signed: number;
  status: number;
  comp: number;
  reimburse: number;
}

export interface NewCollaboratorBidPaginator
  extends PaginatorInfo<CollaboratorBid> {}

export interface CollaboratorBidAllResponse {
  success: boolean;
  message?: string;
  results: CollaboratorBidsPerEvent[];
}

export interface CollaboratorsInEvent {
  success: boolean;
  message?: string;
  results: CollaboratorProfile[];
}

export interface WebCastingBid {
  collab_id: number;
  casting_status: number;
  notes: string;
  order: number;
  casting_fields: string;
  cv: string;
  main_photo: string;
  photo2: string;
  photo3: string;
  photo4: string;
  photo5: string;
  casting_photo1: string;
  casting_photo2: string;
  casting_photo3: string;
  casting_photo4: string;
  info: { [key: string]: any };
}

export interface WebCasting {
  id: number;
  event: Event;
  code: string;
  status: number;
  bids: WebCastingBid[];
  created_at: string;
  updated_at: string;
}

export interface WebCastingResponse {
  success: boolean;
  message?: string;
  data?: WebCasting;
}

export interface WebCastingPaginator extends PaginatorInfo<WebCasting> {}

export interface DownloadInput {
  shoe_sizes: { id: number; name: string }[];
  hair_colors: { id: number; name: string }[];
  language_levels: { id: number; name: string }[];
  positions: string[];
}

export interface DownloadResponse {
  success: boolean;
  message?: string;
  list: [{ user: User; profile?: CollaboratorProfile }];
}

export interface InvoiceCreateInput {
  amount: number;
  date: string;
  due_date: string;
  client_id: number;
}

export interface SimpleEventInfo {
  id: number;
  name: string;
  invoice_no: string;
  start_date: string;
  end_date: string;
}
export interface Invoice {
  id: number;
  client: Client;
  event: SimpleEventInfo;
  amount: number;
  date: string;
  due_date: string;
  status: number;
  created_at: string;
  updated_at: string;
}

export interface InvoicePaginator extends PaginatorInfo<Invoice> {}

export interface CurrentInvoiceStatus {
  total_invoice: number;
  paid_invoice: number;
  pending_invoice: number;
  archived_invoice: number;
}

export interface InvoiceStatusResponse {
  success: boolean;
  status: CurrentInvoiceStatus;
}

export interface ReportStatus {
  total_clients: number;
  total_collabs: number;
  total_admins: number;
  total_events: number;
}

export interface ReportStatusResponse {
  success: boolean;
  message?: string;
  status: ReportStatus;
  events: { [key: string]: number };
}

export interface SignContractInput {
  bid_id: number;
  contract: any;
}

export interface CurrentPaymentStatus {
  total_payment: number;
  paid_payment: number;
  pending_payment: number;
}

export interface PaymentStatusResponse {
  success: boolean;
  status: CurrentPaymentStatus;
}

export interface ClientCost {
  client: Client;
  cost: number;
}

export interface ClientCostPaginator extends PaginatorInfo<ClientCost> {}

export interface Payment {
  id: number;
  collaborator: {
    id: number;
    first_name: string;
    last_name: string;
    info: { [key: string]: any };
  };
  event: SimpleEventInfo;
  amount: number;
  dates: string[];
  reimburse: number;
  date: string;
  due_date: string;
  status: number;
  created_at: string;
  updated_at: string;
}

export interface PaymentPaginator extends PaginatorInfo<Payment> {}

export interface JobAdsSendInput {
  event_id: number;
  details: string;
  process_details: string;
  send_option: number;
  user_ids: number[];
}

export interface WebCastingPhotosInput {
  collab_id: number;
  orders: string[];
  photo1?: any;
  photo2?: any;
  photo3?: any;
  photo4?: any;
}
export interface CastingPhotos {
  collaborator: CollaboratorProfile;
  photo1: string;
  photo2: string;
  photo3: string;
  photo4: string;
  created_at: string;
  updated_at: string;
}

export interface CastingPhotosUpdateResponse {
  success: boolean;
  message?: string;
  data: CastingPhotos;
}

export interface WebcastingPhotos {
  collaborator: CollaboratorProfile;
  photos?: CastingPhotos;
}

export interface WebcastingPhotosResponse {
  success: boolean;
  message?: string;
  data: WebcastingPhotos[];
}

export interface AdsSearchQueryOptions {
  page: number;
  per_page: number;
  sort: string;
  direction: string;
}

export interface JobAds {
  event: Event;
  event_name: string;
  order: number;
  collab_amount: number;
  details: string;
  send_option: number;
  created_at: string;
}

export interface JobAdsDetails {
  id: number;
  order: number;
  send_option: number;
  event: SimpleEventInfo;
  collaborator: { id: number; first_name: string; last_name: string };
  details: string;
  status: boolean;
  created_at: string;
  updated_at: string;
}

export interface JobAdsPaginator extends PaginatorInfo<JobAds> {}

export interface JobAdsListResponse {
  success: boolean;
  message?: string;
  data: JobAdsDetails[];
}

export interface Settings {
  standard_contract: string;
  vat_number: string;
  email: string;
}

export interface SettingsResponse {
  success: boolean;
  message?: string;
  dates?: string[];
  data: Settings;
}

export interface SettingsTemplateInput {
  standard: any;
  standard_extension: string;
  vat: any;
  vat_extension: string;
}

export interface SettingsEmailInput {
  email: string;
}
