import type {
  User,
  LoginUserInput,
  RegisterUserInput,
  ChangePasswordInput,
  LogoutInput,
  UpdateProfileInput,
  DeletePhotoInput,
  UserResponse,
  StatusResponse,
  UsersResponse,
  SearchQueryOptions,
  UserPaginator,
  UpdateClientInput,
  RegisterClientInput,
  ClientPaginator,
  ClientsResponse,
  EventPreRegisterInput,
  EventResponse,
  EventsResponse,
  EventPaginator,
  EventRegisterInput,
  EventListByDateResponse,
  EventListByWeekResponse,
  BidInput,
  CollaboratorUpdateProfileInput,
  CollaboratorProfileResponse,
  CollaboratorUpdateProfileResponse,
  BidWithProfileInput,
  AmountResponse,
  CollaboratorBidPaginator,
  WebCastingCodeResponse,
  WebCastingPaginator,
  WebCastingResponse,
  CollaboratorBidAllResponse,
  CollaboratorsResponse,
  EventByIDResponse,
  InvoiceCreateInput,
  InvoiceSearchQueryOptions,
  InvoicePaginator,
  InvoiceStatusResponse,
  ReportStatusResponse,
  SignContractInput,
  PaymentStatusResponse,
  PaymentSearchQueryOptions,
  ClientCostPaginator,
  BidCompValueUpdateInput,
  CollaboratorCreateProfileInput,
  CollaboratorProfilesResponse,
  EventUpdateDatesInput,
  PaymentPaginator,
  CollaboratorsInEvent,
  EventUpdateInput,
  JobAdsSendInput,
  WebCastingPhotosInput,
  CastingPhotosUpdateResponse,
  WebcastingPhotosResponse,
  AdsSearchQueryOptions,
  JobAdsPaginator,
  JobAdsListResponse,
  NewCollaboratorBidPaginator,
  SettingsResponse,
  SettingsTemplateInput,
  SettingsEmailInput,
  CollaboratorCurrentUpdateProfileInput,
  CollaboratorEventDatesResponse,
  BasicCollaboratorProfilesResponse,
  CollabProfileQueryOptions
} from "./types";
import { API_ENDPOINTS } from "./endpoints";
import { HttpClient } from "./http-client";

class Client {
  users = {
    me: () => HttpClient.get<UserResponse>(API_ENDPOINTS.USERS_ME),
    updateLocation: (input: { lat: number; lng: number }) =>
      HttpClient.post<StatusResponse>(
        API_ENDPOINTS.USER_LOCATION_UPDATE,
        input
      ),
    login: (input: LoginUserInput) =>
      HttpClient.post<UserResponse>(API_ENDPOINTS.USERS_LOGIN, input),
    collabRegister: (input: RegisterUserInput) =>
      HttpClient.post<StatusResponse>(API_ENDPOINTS.COLLAB_REGISTER, input),
    basicAdminRegister: (input: RegisterUserInput) =>
      HttpClient.post<StatusResponse>(
        API_ENDPOINTS.BASIC_ADMIN_REGISTER,
        input
      ),
    edit: (input: { user_id: number; data: UpdateProfileInput }) =>
      HttpClient.post<StatusResponse>(
        `${API_ENDPOINTS.EDIT_USER}/${input.user_id}`,
        input.data
      ),
    delete: (user_id: number) =>
      HttpClient.get<StatusResponse>(`${API_ENDPOINTS.DELETE_USER}/${user_id}`),
    approve: (user_id: number) =>
      HttpClient.get<StatusResponse>(
        `${API_ENDPOINTS.APPROVE_USER}/${user_id}`
      ),
    changePassword: (input: ChangePasswordInput) =>
      HttpClient.post<StatusResponse>(API_ENDPOINTS.CHANGE_PASSWORD, input),
    forgotPassword: (input: { email: string }) =>
      HttpClient.post<StatusResponse>(API_ENDPOINTS.FORGOT_PASSWORD, input),
    resetPassword: (input: { token: string; password: string }) =>
      HttpClient.post<StatusResponse>(API_ENDPOINTS.RESET_PASSWORD, input),
    updateProfile: (input: UpdateProfileInput) =>
      HttpClient.post<UserResponse>(API_ENDPOINTS.UPDATE_PROFILE, input),
    updateAvatar: (input: File) => {
      let formData = new FormData();
      formData.append("avatar", input);
      return HttpClient.post<UserResponse>(
        API_ENDPOINTS.UPDATE_AVATAR,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    },
    deletePhoto: (input: DeletePhotoInput) =>
      HttpClient.post<UserResponse>(API_ENDPOINTS.DELETE_PHOTO, input),
    addPhoto: (input: File) => {
      let formData = new FormData();
      formData.append("photo", input);
      return HttpClient.post<UserResponse>(API_ENDPOINTS.ADD_PHOTO, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    logout: (input: LogoutInput) =>
      HttpClient.post<StatusResponse>(API_ENDPOINTS.USERS_LOGOUT, input),
    collabUsersList: (query?: CollabProfileQueryOptions) =>
      HttpClient.get<UserPaginator>(API_ENDPOINTS.COLLAB_USERS, {
        ...query,
      }),
    collabUsersAll: () =>
      HttpClient.get<CollaboratorsResponse>(API_ENDPOINTS.COLLAB_USERS_ALL),
    /*
    collabUsersAll: () =>
      HttpClient.get<CollaboratorsResponse>(
        `${API_ENDPOINTS.COLLAB_USERS}?all=true`
      ),
      */
    leaveReview: (input: { user_id: number; rate: number; feedback: string }) =>
      HttpClient.post<StatusResponse>(
        `${API_ENDPOINTS.COLLAB_LEAVE_REVIEW}/${input.user_id}`,
        input
      ),
    remind: (user_id: number) =>
      HttpClient.get<StatusResponse>(
        `${API_ENDPOINTS.COLLAB_REMIND}/${user_id}`
      ),
    basicAdminUsersList: (query?: SearchQueryOptions) =>
      HttpClient.get<UserPaginator>(API_ENDPOINTS.BASIC_ADMIN_USERS, {
        ...query,
      }),
    basicAdminUsersAll: () =>
      HttpClient.get<CollaboratorsResponse>(
        `${API_ENDPOINTS.BASIC_ADMIN_USERS}?all=true`
      ),
  };
  clients = {
    all: () =>
      HttpClient.get<ClientsResponse>(`${API_ENDPOINTS.CLIENTS}?all=true`),
    list: (query?: SearchQueryOptions) =>
      HttpClient.get<ClientPaginator>(API_ENDPOINTS.CLIENTS, {
        ...query,
      }),
    register: (input: RegisterClientInput) =>
      HttpClient.post<StatusResponse>(API_ENDPOINTS.CLIENT_REGISTER, input),
    edit: (input: { client_id: number; data: UpdateClientInput }) =>
      HttpClient.post<StatusResponse>(
        `${API_ENDPOINTS.EDIT_CLIENT}/${input.client_id}`,
        input.data
      ),
    delete: (client_id: number) =>
      HttpClient.get<StatusResponse>(
        `${API_ENDPOINTS.DELETE_CLIENT}/${client_id}`
      ),
  };
  events = {
    all: (input: string) =>
      HttpClient.get<EventsResponse>(`${API_ENDPOINTS.EVENTS}?${input}=true`),
    get: (event_id: number) =>
      HttpClient.get<EventByIDResponse>(
        `${API_ENDPOINTS.EVENT_BY_ID}/${event_id}`
      ),
    update: (input: EventUpdateInput) =>
      HttpClient.post<StatusResponse>(
        `${API_ENDPOINTS.EVENT_BY_ID}/${input.event_id}`,
        input
      ),
    listByWeek: (input: { start_date: string; end_date: string }) =>
      HttpClient.get<EventListByWeekResponse>(
        `${API_ENDPOINTS.EVENT_LIST_BY_WEEK}?start_date=${input.start_date}&end_date=${input.end_date}`
      ),
    listByDate: (input: { month: number; year: number }) =>
      HttpClient.get<EventListByDateResponse>(
        `${API_ENDPOINTS.EVENT_LIST_BY_DATE}?year=${input.year}&month=${input.month}`
      ),
    list: (query?: SearchQueryOptions) =>
      HttpClient.get<EventPaginator>(API_ENDPOINTS.EVENTS, {
        ...query,
      }),
    preRegister: (input: EventPreRegisterInput) =>
      HttpClient.post<EventResponse>(API_ENDPOINTS.EVENT_PRE_REGISTER, input),
    register: (input: EventRegisterInput) =>
      HttpClient.post<EventResponse>(
        `${API_ENDPOINTS.EVENT_REGISTER}/${input.event_id}`,
        input
      ),
    archive: (input: { event_id: number; invoice_id?: number }) =>
      HttpClient.post<StatusResponse>(
        `${API_ENDPOINTS.EVENT_ARCHIVE}/${input.event_id}`,
        input
      ),
    delete: (input: number) =>
      HttpClient.get<StatusResponse>(`${API_ENDPOINTS.EVENT_DELETE}/${input}`),
    updateDates: (input: EventUpdateDatesInput) =>
      HttpClient.post<EventResponse>(
        `${API_ENDPOINTS.EVENT_UPDATE_DATES}/${input.event_id}`,
        input
      ),
    getReceivedContractsAmount: (input: number) =>
      HttpClient.get<AmountResponse>(
        `${API_ENDPOINTS.EVENT_LIST_BY_WEEK}/${input}`
      ),
    getCollaboratorBidListAll: (input: number) =>
      HttpClient.get<CollaboratorBidAllResponse>(
        `${API_ENDPOINTS.COLLABORATOR_BIDS_PER_EVENT}/${input}?all=true`
      ),
    getCollaborators: (input: number) =>
      HttpClient.get<CollaboratorsInEvent>(
        `${API_ENDPOINTS.COLLABORATORS_IN_EVENT}/${input}`
      ),

    getCollaboratorBidList: (input: {
      id: number;
      query?: SearchQueryOptions;
    }) =>
      HttpClient.get<CollaboratorBidPaginator>(
        `${API_ENDPOINTS.COLLABORATOR_BIDS_PER_EVENT}/${input.id}`,
        {
          ...input.query,
        }
      ),
    shortlist: (input: { event_id: number; bid_ids: string }) =>
      HttpClient.post<WebCastingCodeResponse>(
        `${API_ENDPOINTS.SHORTLIST_BIDS}/${input.event_id}`,
        input
      ),
    deleteBids: (input: { event_id: number; bid_ids: string }) =>
      HttpClient.post<StatusResponse>(
        `${API_ENDPOINTS.DELETE_BIDS}/${input.event_id}`,
        input
      ),
    reorderCollabs: (input: { event_id: number; orders: string[] }) =>
      HttpClient.post<StatusResponse>(
        API_ENDPOINTS.COLLABORATORS_REORDER_IN_EVENT,
        input
      ),
    sendContract: (input: {
      bid_id: number;
      email_content: string;
      contract_mode: number;
      params: string;
    }) =>
      HttpClient.post<StatusResponse>(
        `${API_ENDPOINTS.SEND_CONTRACT}/${input.bid_id}`,
        input
      ),
    updateCompValues: (input: BidCompValueUpdateInput) =>
      HttpClient.post<StatusResponse>(
        `${API_ENDPOINTS.UPDATE_COMP_VALUES}/${input.bid_id}`,
        input
      ),
    getAvailableCollaborators: (input: number) =>
      HttpClient.get<CollaboratorProfilesResponse>(
        `${API_ENDPOINTS.AVAILABLE_COLLABORATORS_IN_EVENT}/${input}`
      ),
    getAvailableCollaboratorsForReplace: (input: number) =>
      HttpClient.get<BasicCollaboratorProfilesResponse>(
        `${API_ENDPOINTS.COLLABORATORS_FOR_REPLACE}/${input}`
      ),
    addCollabs: (input: {
      event_id: number;
      collab_ids: number[];
      dates: string[];
    }) =>
      HttpClient.post<StatusResponse>(
        `${API_ENDPOINTS.ADD_COLLABORATORS_IN_EVENT}/${input.event_id}`,
        input
      ),
  };
  collaborators = {
    events: () =>
      HttpClient.get<EventsResponse>(API_ENDPOINTS.COLLABORATOR_EVENTS),
    getAllBids: (query?: SearchQueryOptions) =>
      HttpClient.get<NewCollaboratorBidPaginator>(
        API_ENDPOINTS.COLLABORATOR_ALL_BIDS,
        {
          ...query,
        }
      ),
    getProfile: (user_id: number) =>
      HttpClient.get<CollaboratorProfileResponse>(
        `${API_ENDPOINTS.COLLABORATOR_PROFILE}/${user_id}`
      ),
    bidWithProfile: (input: BidWithProfileInput) =>
      HttpClient.post<StatusResponse>(API_ENDPOINTS.BID_WITH_PROFILE, input),
    updateStatus: (input: { bid_id: number; status: number }) =>
      HttpClient.post<StatusResponse>(
        `${API_ENDPOINTS.COLLABORATOR_UPDATE_STATUS}/${input.bid_id}`,
        input
      ),
    updateContractStatus: (input: { bid_id: number; status: number }) =>
      HttpClient.post<StatusResponse>(
        `${API_ENDPOINTS.COLLABORATOR_UPDATE_CONTRACT_STATUS}/${input.bid_id}`,
        input
      ),
    getEventDates: (bid_id: number) =>
      HttpClient.get<CollaboratorEventDatesResponse>(
        `${API_ENDPOINTS.COLLABORATOR_EVENT_DATES}/${bid_id}`
      ),
    confirmProfile: (profile_id: number) =>
      HttpClient.get<StatusResponse>(
        `${API_ENDPOINTS.COLLABORATOR_PROFILE_CONFIRM}/${profile_id}`
      ),
    cancelContract: (bid_id: number) =>
      HttpClient.get<StatusResponse>(
        `${API_ENDPOINTS.COLLABORATOR_CANCEL_CONTRACT}/${bid_id}`
      ),
    createProfile: (input: CollaboratorCreateProfileInput) => {
      let formData = new FormData();
      formData.append("first_name", input.first_name);
      formData.append("last_name", input.last_name);
      formData.append("password", input.password);
      formData.append("email", input.email);
      formData.append("video1", input.video1);
      formData.append("video_extension1", input.video_extension1);
      formData.append("video2", input.video2);
      formData.append("video_extension2", input.video_extension2);
      formData.append("cv", input.cv);
      formData.append("cv_extension", input.cv_extension);
      formData.append("info", input.info);
      formData.append("main_photo", input.main_photo);
      formData.append("photo2", input.photo2);
      formData.append("photo3", input.photo3);
      formData.append("photo4", input.photo4);
      formData.append("photo5", input.photo5);
      formData.append("photo6", input.photo6);
      formData.append("photo7", input.photo7);
      formData.append("photo8", input.photo8);
      formData.append("photo9", input.photo9);

      return HttpClient.post<CollaboratorUpdateProfileResponse>(
        API_ENDPOINTS.COLLABORATOR_ADD_PROFILE,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    },
    getBidsList: (input: { event_id: number; query?: SearchQueryOptions }) =>
      HttpClient.get<CollaboratorBidPaginator>(
        `${API_ENDPOINTS.COLLABORATOR_BIDS}/${input.event_id}`,
        {
          ...input.query,
        }
      ),
    updateCurrentProfile: (input: CollaboratorCurrentUpdateProfileInput) => {
      let formData = new FormData();
      formData.append("video1", input.video1);
      formData.append("video_extension1", input.video_extension1);
      formData.append(
        "original_video_filename1",
        input.original_video_filename1
      );
      formData.append("video2", input.video2);
      formData.append("video_extension2", input.video_extension2);
      formData.append(
        "original_video_filename2",
        input.original_video_filename2
      );
      formData.append("cv", input.cv);
      formData.append("cv_extension", input.cv_extension);
      formData.append("info", input.info);
      formData.append("main_photo", input.main_photo);
      formData.append("email", input.email);
      formData.append("photo2", input.photo2);
      formData.append("photo3", input.photo3);
      formData.append("photo4", input.photo4);
      formData.append("photo5", input.photo5);
      formData.append("photo6", input.photo6);
      formData.append("photo7", input.photo7);
      formData.append("photo8", input.photo8);
      formData.append("photo9", input.photo9);

      return HttpClient.post<CollaboratorUpdateProfileResponse>(
        API_ENDPOINTS.COLLABORATOR_CURRENT_UPDATE_PROFILE,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    },
    updateProfile: (input: CollaboratorUpdateProfileInput) => {
      let formData = new FormData();
      formData.append("cv", input.cv);
      formData.append("cv_extension", input.cv_extension);
      formData.append("video1", input.video1);
      formData.append("video_extension1", input.video_extension1);
      formData.append(
        "original_video_filename1",
        input.original_video_filename1
      );
      formData.append("video2", input.video2);
      formData.append("video_extension2", input.video_extension2);
      formData.append(
        "original_video_filename2",
        input.original_video_filename2
      );
      formData.append("info", input.info);
      formData.append("main_photo", input.main_photo);
      formData.append("email", input.email);
      formData.append("photo2", input.photo2);
      formData.append("photo3", input.photo3);
      formData.append("photo4", input.photo4);
      formData.append("photo5", input.photo5);
      formData.append("photo6", input.photo6);
      formData.append("photo7", input.photo7);
      formData.append("photo8", input.photo8);
      formData.append("photo9", input.photo9);
      formData.append("orders", input.orders.join(","));
      formData.append("current_photos", input.current_photos.join(","));

      return HttpClient.post<CollaboratorUpdateProfileResponse>(
        `${API_ENDPOINTS.COLLABORATOR_UPDATE_PROFILE}/${input.user_id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    },
    signContract: (input: SignContractInput) => {
      let formData = new FormData();
      formData.append("contract", input.contract);

      return HttpClient.post<StatusResponse>(
        `${API_ENDPOINTS.SIGN_CONTRACT}/${input.bid_id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    },
  };
  invoices = {
    status: () =>
      HttpClient.get<InvoiceStatusResponse>(API_ENDPOINTS.INVOICE_STATUS),
    list: (query?: InvoiceSearchQueryOptions) =>
      HttpClient.get<InvoicePaginator>(API_ENDPOINTS.INVOICES, {
        ...query,
      }),
    markAsPaid: (input: { invoice_ids: number[] }) =>
      HttpClient.post<StatusResponse>(
        API_ENDPOINTS.INVOICE_MARK_AS_PAID,
        input
      ),
    create: (input: InvoiceCreateInput) =>
      HttpClient.post<StatusResponse>(API_ENDPOINTS.INVOICE_CREATE, input),
    archive: (input: number) =>
      HttpClient.get<StatusResponse>(
        `${API_ENDPOINTS.INVOICE_ARCHIVE}/${input}`
      ),
    delete: (input: number) =>
      HttpClient.get<StatusResponse>(
        `${API_ENDPOINTS.INVOICE_DELETE}/${input}`
      ),
  };
  webCasting = {
    list: (query?: SearchQueryOptions) =>
      HttpClient.get<WebCastingPaginator>(API_ENDPOINTS.WEBCASTING_LIST, {
        ...query,
      }),
    view: (code: string) =>
      HttpClient.get<WebCastingResponse>(
        `${API_ENDPOINTS.WEBCASTING_LIST}/${code}`
      ),
    reset: (input: number) =>
      HttpClient.get<StatusResponse>(
        `${API_ENDPOINTS.WEBCASTING_RESET}/${input}`
      ),
    archive: (input: number) =>
      HttpClient.get<StatusResponse>(
        `${API_ENDPOINTS.WEBCASTING_ARCHIVE}/${input}`
      ),
    delete: (input: number) =>
      HttpClient.get<StatusResponse>(
        `${API_ENDPOINTS.WEBCASTING_DELETE}/${input}`
      ),
    confirm: (input: {
      event_id: number;
      code: string;
      casting_result: string;
    }) =>
      HttpClient.post<StatusResponse>(
        `${API_ENDPOINTS.WEBCASTING_CONFIRM}/${input.event_id}`,
        input
      ),
    getPhotos: (input: number) =>
      HttpClient.get<WebcastingPhotosResponse>(
        `${API_ENDPOINTS.WEBCASTING_PHOTOS}/${input}`
      ),
    updatePhotos: (input: WebCastingPhotosInput) => {
      let formData = new FormData();
      formData.append("photo1", input.photo1);
      formData.append("photo2", input.photo2);
      formData.append("photo3", input.photo3);
      formData.append("photo4", input.photo4);
      formData.append("orders", input.orders.join(","));

      return HttpClient.post<CastingPhotosUpdateResponse>(
        `${API_ENDPOINTS.WEBCASTING_PHOTOS_UPDATE}/${input.collab_id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    },
  };
  ads = {
    send: (input: JobAdsSendInput) =>
      HttpClient.post<StatusResponse>(API_ENDPOINTS.SEND_JOB_ADS, input),
    list: (query?: AdsSearchQueryOptions) =>
      HttpClient.get<JobAdsPaginator>(API_ENDPOINTS.JOB_ADS_LIST, {
        ...query,
      }),
    details: (input: number) =>
      HttpClient.get<JobAdsListResponse>(
        `${API_ENDPOINTS.JOB_ADS_LIST}/${input}`
      ),
  };
  reports = {
    status: (year: number) =>
      HttpClient.get<ReportStatusResponse>(
        `${API_ENDPOINTS.REPORT_STATUS}?year=${year}`
      ),
    events: (query?: SearchQueryOptions) =>
      HttpClient.get<EventPaginator>(API_ENDPOINTS.REPORT_EVENTS, {
        ...query,
      }),
    collabs: (query?: SearchQueryOptions) =>
      HttpClient.get<CollaboratorBidPaginator>(API_ENDPOINTS.REPORT_COLLABS, {
        ...query,
      }),
    clients: (query?: SearchQueryOptions) =>
      HttpClient.get<ClientCostPaginator>(API_ENDPOINTS.REPORT_CLIENTS_COST, {
        ...query,
      }),
  };
  payments = {
    status: () =>
      HttpClient.get<PaymentStatusResponse>(API_ENDPOINTS.PAYMENT_STATUS),
    list: (query?: PaymentSearchQueryOptions) =>
      HttpClient.get<PaymentPaginator>(API_ENDPOINTS.PAYMENTS, {
        ...query,
      }),
    markAsPaid: (input: { payment_ids: number[] }) =>
      HttpClient.post<StatusResponse>(
        API_ENDPOINTS.PAYMENT_MARK_AS_PAID,
        input
      ),
    archive: (input: number) =>
      HttpClient.get<StatusResponse>(
        `${API_ENDPOINTS.PAYMENT_ARCHIVE}/${input}`
      ),
    delete: (input: number) =>
      HttpClient.get<StatusResponse>(
        `${API_ENDPOINTS.PAYMENT_DELETE}/${input}`
      ),
  };
  contract = {
    get: () => HttpClient.get<SettingsResponse>(API_ENDPOINTS.CONTRACT),
    getWithDates: (bid_id: number) =>
      HttpClient.get<SettingsResponse>(
        `${API_ENDPOINTS.CONTRACT_WITH_DATES}/${bid_id}`
      ),
    updateTemplates: (input: SettingsTemplateInput) => {
      let formData = new FormData();
      formData.append("standard", input.standard);
      formData.append("standard_extension", input.standard_extension);
      formData.append("vat", input.vat);
      formData.append("vat_extension", input.vat_extension);

      return HttpClient.post<SettingsResponse>(
        API_ENDPOINTS.CONTRACT_TEMPLATES,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    },
    updateEmail: (input: SettingsEmailInput) =>
      HttpClient.post<SettingsResponse>(API_ENDPOINTS.CONTRACT_EMAIL, input),
  };
}

export default new Client();
