import moment from "moment";
import { InvoiceDataType, PaymentDataType } from "./data-types";
import { makeAmountForXML, makeNumberWithFloatingDigits } from "./utils";

export const generatePaymentXML = (list: PaymentDataType[]) => {
  let totalAmount = 0;
  list.map((item) => (totalAmount += item.total_amount));

  const headerContent = `
        <GrpHdr xmlns="urn:CBI:xsd:CBIPaymentRequest.00.04.00">
            <MsgId>DistintaXml-${moment().format("DDMMYY-HH.mm")}</MsgId>
            <CreDtTm>${moment().format("YYYY-MM-DDTHH:mm:ss")}</CreDtTm>
            <NbOfTxs>${list.length}</NbOfTxs>
            <CtrlSum>${makeAmountForXML(totalAmount)}</CtrlSum>
            <InitgPty>
                <Nm>WITH YOU s.r.l.</Nm>
                <Id>
                    <OrgId>
                        <Othr>
                            <Id>SIACJ36D</Id>
                            <Issr>CBI</Issr>
                        </Othr>
                    </OrgId>
                </Id>
            </InitgPty>
        </GrpHdr>        
    `;

  const recipients = list.map(
    (item, index) => `
        <CdtTrfTxInf xmlns="urn:CBI:xsd:CBIPaymentRequest.00.04.00">
            <PmtId>
                <InstrId>${index + 1}</InstrId>
                <EndToEndId>${moment().format("DDMMYY-HH.mm")}-${(index + 1)
      .toString()
      .padStart(4, "0")}</EndToEndId>
            </PmtId>
            <PmtTpInf>
                <CtgyPurp>
                    <Cd>SUPP</Cd>
                </CtgyPurp>
            </PmtTpInf>
            <Amt>
                <InstdAmt Ccy="EUR">${makeAmountForXML(
                  item.total_amount
                )}</InstdAmt>
            </Amt>
            <Cdtr>
                <Nm>${item.collab_info.current_account_holder}</Nm>
            </Cdtr>
            <CdtrAcct>
                <Id>
                    <IBAN>${item.collab_info.IBAN}</IBAN>
                </Id>
            </CdtrAcct> 
            <RmtInf>
                <Ustrd>CJ36D</Ustrd>
            </RmtInf>
        </CdtTrfTxInf>        
    `
  );
  const bodyContent = `
        <PmtInf xmlns="urn:CBI:xsd:CBIPaymentRequest.00.04.00">
            <PmtInfId>DistintaXml-${moment().format("DDMMYY-HH.mm")}</PmtInfId>
            <PmtMtd>TRF</PmtMtd>
            <PmtTpInf>
                <InstrPrty>NORM</InstrPrty>
                <SvcLvl>
                    <Cd>SEPA</Cd>
                </SvcLvl>
            </PmtTpInf>
            <ReqdExctnDt>${moment().format("YYYY-MM-DD")}</ReqdExctnDt>
            <Dbtr>
                <Nm>WITH YOU s.r.l.</Nm>
            </Dbtr>
            <DbtrAcct>
                <Id>
                    <IBAN>IT37O0306904072100000003455</IBAN>
                </Id>
            </DbtrAcct>
            <DbtrAgt>
                <FinInstnId>
                    <ClrSysMmbId>
                        <MmbId>03069</MmbId>
                    </ClrSysMmbId>
                </FinInstnId>
            </DbtrAgt>
            <ChrgBr>SLEV</ChrgBr>
            ${recipients.join("\n")}
       </PmtInf> 
    `;
  const xmlContent = `
        <CBIBdyPaymentRequest xmlns="urn:CBI:xsd:CBIBdyPaymentRequest.00.04.00" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="urn:CBI:xsd:CBIBdyPaymentRequest.00.04.00 CBIBdyPaymentRequest.00.04.00.xsd">        
            <CBIEnvelPaymentRequest>
                <CBIPaymentRequest>        
                    ${headerContent}
                    ${bodyContent}
                </CBIPaymentRequest>
            </CBIEnvelPaymentRequest>
        </CBIBdyPaymentRequest>
    `;

  return xmlContent;
};


  
export const generateInvoiceXML = (invoice: InvoiceDataType) => {
  const amount = Number(invoice.amount) ?? 0;
  const vat_amount = amount * 0.22;
  const total_amount = amount + vat_amount;

  const xmlContent = `
        <p:FatturaElettronica xmlns:p="http://ivaservizi.agenziaentrate.gov.it/docs/xsd/fatture/v1.2" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:ds="http://www.w3.org/2000/09/xmldsig#" versione="FPR12">
            <FatturaElettronicaHeader>
                <DatiTrasmissione>
                    <IdTrasmittente>
                        <IdPaese>IT</IdPaese>
                        <IdCodice>01879020517</IdCodice>
                    </IdTrasmittente>
                    <ProgressivoInvio>${invoice.id
                      .toString()
                      .padStart(4, "0")}</ProgressivoInvio>
                    <FormatoTrasmissione>FPR12</FormatoTrasmissione>
                    <CodiceDestinatario>${invoice.client.recipient_code.toUpperCase()}</CodiceDestinatario>
                    <ContattiTrasmittente>
                        <Telefono>0804671383</Telefono>
                        <Email>info@withyouagency.it</Email>
                    </ContattiTrasmittente>
                </DatiTrasmissione>
                <CedentePrestatore>
                    <DatiAnagrafici>
                        <IdFiscaleIVA>
                            <IdPaese>IT</IdPaese>
                            <IdCodice>10940150963</IdCodice>
                        </IdFiscaleIVA>
                        <Anagrafica>
                            <Denominazione>WITH YOU s.r.l. </Denominazione>
                        </Anagrafica>
                        <RegimeFiscale>RF01</RegimeFiscale>
                    </DatiAnagrafici>
                    <Sede>
                        <Indirizzo>Via Privata del Gonfalone, 3</Indirizzo>
                        <CAP>20123</CAP>
                        <Comune>Milano (MI)</Comune>
                        <Provincia>MI</Provincia>
                        <Nazione>IT</Nazione>
                    </Sede>
                </CedentePrestatore>
                <CessionarioCommittente>
                    <DatiAnagrafici>
                        <IdFiscaleIVA>
                            <IdPaese>IT</IdPaese>
                            <IdCodice>${invoice.client.vat_number}</IdCodice>
                        </IdFiscaleIVA>
                        <Anagrafica>
                            <Denominazione>${
                              invoice.client_name
                            }</Denominazione>
                        </Anagrafica>
                    </DatiAnagrafici>
                    <Sede>
                        <Indirizzo>${invoice.client.address}</Indirizzo>
                        <CAP>${invoice.client.zip}</CAP>
                        <Comune>${invoice.client.city}</Comune>
                        <Nazione>IT</Nazione>
                    </Sede>
                </CessionarioCommittente>
                <TerzoIntermediarioOSoggettoEmittente>
                    <DatiAnagrafici>
                        <IdFiscaleIVA>
                            <IdPaese>IT</IdPaese>
                            <IdCodice>10940150963</IdCodice>
                        </IdFiscaleIVA>
                        <Anagrafica>
                            <Denominazione>${
                              invoice.client_name
                            }</Denominazione>
                        </Anagrafica>
                    </DatiAnagrafici>
                </TerzoIntermediarioOSoggettoEmittente>
                <SoggettoEmittente>TZ</SoggettoEmittente>
            </FatturaElettronicaHeader>
            <FatturaElettronicaBody>
                <DatiGenerali>
                    <DatiGeneraliDocumento>
                        <TipoDocumento>TD01</TipoDocumento>
                        <Divisa>EUR</Divisa>
                        <Data>${moment().format("YYYY-MM-DD")}</Data>
                        <Numero>${invoice.id}</Numero>
                        <ImportoTotaleDocumento>${makeAmountForXML(
                          total_amount
                        )}</ImportoTotaleDocumento>
                    </DatiGeneraliDocumento>
                </DatiGenerali>
                <DatiBeniServizi>
                    <DettaglioLinee>
                        <NumeroLinea>1</NumeroLinea>
                        <Descrizione>${invoice.event_name} - ${
    invoice.invoice_no
  }</Descrizione>
                        <Quantita>1.00</Quantita>
                        <UnitaMisura>non asseg</UnitaMisura>
                        <PrezzoUnitario>${makeAmountForXML(
                          amount
                        )}</PrezzoUnitario>
                        <PrezzoTotale>${makeAmountForXML(amount)}</PrezzoTotale>
                        <AliquotaIVA>22.00</AliquotaIVA>
                    </DettaglioLinee>
                    <DatiRiepilogo>
                        <AliquotaIVA>22.00</AliquotaIVA>
                        <ImponibileImporto>${makeAmountForXML(
                          amount
                        )}</ImponibileImporto>
                        <Imposta>${makeAmountForXML(vat_amount)}</Imposta>
                        <EsigibilitaIVA>I</EsigibilitaIVA>
                        <RiferimentoNormativo>22%</RiferimentoNormativo>
                    </DatiRiepilogo>
                </DatiBeniServizi>
            </FatturaElettronicaBody>
        </p:FatturaElettronica>        
    `;
  return xmlContent;
};
