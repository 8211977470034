import { FC, useContext, useEffect, useState } from "react";
import { CalendarPlusIcon } from "src/components/icons/calendar-plus-icon";
import Button from "src/components/ui/button";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import InputWithoutLabel from "src/components/ui/input-without-label";
import DropdownWithoutLabel from "src/components/ui/dropdown-without-label";
import TailwindDatePicker from "src/components/ui/date-picker";
import { EventStatus, LIST_PAGE_SIZE } from "src/lib/constants";
import {
  CollaboratorBidType,
  CollabStatusType,
  EventDataType,
} from "src/lib/data-types";
import { Dropdown, Empty, MenuProps, Space, Spin, Table } from "antd";
import type { ColumnsType, TableProps } from "antd/es/table";
import {
  addPlusIntoPhoneNumber,
  capitalizeFirstLetter,
  getBidStatusColor,
  getBidStatusName,
  getPaidStatusColor,
  getPaidStatusName,
  makeFormattedDateString,
  makeNumberWithFloatingDigits,
} from "src/lib/utils";
import { useNavigate } from "react-router-dom";
import routes from "../../config/routes";
import { SortIcon } from "src/components/icons/sort-icon";
import { SearchQueryOptions } from "src/api/types";
import GlobalContext from "src/context/global-context";
import { useMutation } from "react-query";
import client from "../../api";
import { UserIcon } from "src/components/icons/user-icon";
import L from "i18n-react";
import MonthPicker from "src/components/ui/month-picker";
import { makeColumnHeader } from "src/components/table-columns/make-column-header";
import moment from "moment";
import { FileEditIcon } from "../icons/file-edit-icon";
import { DocumentIcon } from "../icons/document-icon";
import { DownloadIcon } from "../icons/download-icon";
import { useModalAction } from "../modal-views/context";

const ReportCollaboratorsPage: FC = () => {
  const isMounted = useIsMounted();
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useState<SearchQueryOptions>({
    per_page: LIST_PAGE_SIZE,
    page: 1,
    sort: "user_name",
    direction: "desc",
    event_name: "",
    event_start_date: moment().subtract(1, "months").format("yyyy-MM"),
    event_end_date: moment().format("yyyy-MM"),
  });
  const [total, setTotal] = useState(0);
  const [dataSource, setDataSource] = useState<CollabStatusType[]>([]);
  const { setAlertText, setAlertTitle, setIsAlertOpened, geoPosition } =
    useContext(GlobalContext);
  const { openModal } = useModalAction();

  useEffect(() => {
    if (!isMounted) return;

    const delayDebounceFn = setTimeout(() => {
      getList(searchParams);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [
    isMounted,
    searchParams.page,
    searchParams.sort,
    searchParams.direction,
    searchParams.event_name,
    searchParams.event_start_date,
    searchParams.event_end_date,
  ]);

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);

    return () => window.removeEventListener("resize", updateWindowSize);
  }, []);

  function updateWindowSize() {
    setIsMobile(window.innerWidth < 920 ? true : false);
  }

  const { mutate: getList, isLoading } = useMutation(client.reports.collabs, {
    onSuccess: (data) => {
      setTotal(data.count);
      setDataSource(
        data.results.map((item, index) => {
          let lat: number = 0;
          let lng: number = 0;

          // if (item.user.location) {
          //   const positions = item.user.location.split(",");
          //   if (positions.length >= 2) {
          //     lat = Number(positions[0]);
          //     lng = Number(positions[1]);
          //   }
          // }

          return {
            key: index,
            id: item.id,
            user_name: `${capitalizeFirstLetter(
              item.user.first_name ?? ""
            )} ${capitalizeFirstLetter(item.user.last_name ?? "")}`,
            user_id: item.user.id,
            lat: lat,
            lng: lng,
            user_email: item.user.email,
            cv: item.cv,
            main_photo: item.main_photo,
            photo2: item.photo2,
            photo3: item.photo3,
            photo4: item.photo4,
            photo5: item.photo5,
            photo6: item.photo6,
            photo7: item.photo7,
            photo8: item.photo8,
            photo9: item.photo9,
            instagram: item.info.instagram,
            telephone_number: item.info.telephone_number,
            info: item.info,
            status: item.status,
            comp: item.comp ? `${item.comp}` : "-",
            reimburse: item.reimburse ? `${item.reimburse}` : "-",
            contract_signed: item.contract_signed,
            contract_pdf: item.contract_pdf,
            notes: item.notes ?? "",
            event: item.event,
            event_date: item.event_date,
            payment_date: item.payment_date,
            event_name: item.event?.name ?? "",
            created_at: item.created_at,
            updated_at: item.updated_at,
          };
        })
      );
    },
  });

  const refreshList = () => {
    getList(searchParams);
  };

  const onPageChange = (page: number, pageSize: number) => {
    setSearchParams({ ...searchParams, page });
  };

  /*
  const onEditCompValues = (key: number) => {
    const selectedValues = dataSource.filter((item) => item.key == key);
    if (selectedValues.length == 0) return;

    setTimeout(() => {
      openModal("COMP_DETAILS", {
        payload: selectedValues[0],
        refreshCallback: refreshList,
      });
    }, 300);
  };

  const onDocuments = (key: number) => {
    const selectedValues = dataSource.filter((item) => item.key == key);
    if (selectedValues.length == 0) return;

    setTimeout(
      () =>
        openModal("DOCUMENTS", {
          payload: {
            bidInfo: selectedValues[0],
            eventInfo: selectedValues[0].event,
          },
          refreshCallback: refreshList,
        }),
      300
    );
  };
  */

  const onClickedBid = (data: CollabStatusType) => {
    return;
    openModal("COLLABORATOR_BID", {
      payload: {
        isBid: true,
        bidInfo: data.info,
        main_photo: data.main_photo,
        photo2: data.photo2,
        photo3: data.photo3,
        photo4: data.photo4,
        photo5: data.photo5,
        photo6: data.photo6,
        photo7: data.photo7,
        photo8: data.photo8,
        photo9: data.photo9,
        cv: data.cv,
        lat: data.lat,
        lng: data.lng,
      },
    });
  };

  const columns: ColumnsType<CollabStatusType> = [
    {
      title: () =>
        makeColumnHeader(
          String(L.translate("CommonForm.name")),
          "user_name",
          searchParams,
          setSearchParams
        ),
      dataIndex: "user_name",
      className: "w-auto",
      key: "user_name",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[13px] sm:text-[14px]">
          {L.translate("CommonForm.event_name")}
        </p>
      ),
      dataIndex: "event_name",
      className: "w-auto",
      key: "event_name",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[11px]">{value}</p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[13px] sm:text-[14px]">
          {L.translate("CommonForm.event_date")}
        </p>
      ),
      dataIndex: "event_date",
      className: "w-auto",
      key: "event_date",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {value ? makeFormattedDateString(value) : "-"}
          </p>
        </div>
      ),
    },
    {
      title: () =>
        makeColumnHeader(
          String(L.translate("CommonForm.comp")),
          "comp",
          searchParams,
          setSearchParams
        ),
      dataIndex: "comp",
      className: "w-auto",
      key: "comp",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {makeNumberWithFloatingDigits(value, 2)}
          </p>
        </div>
      ),
    },
    {
      title: () =>
        makeColumnHeader(
          String(L.translate("CommonForm.reimburse")),
          "reimburse",
          searchParams,
          setSearchParams
        ),
      dataIndex: "reimburse",
      className: "w-auto",
      key: "reimburse",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {makeNumberWithFloatingDigits(value, 2)}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[13px] sm:text-[14px]">
          {L.translate("CommonForm.status")}
        </p>
      ),
      dataIndex: "status",
      className: "w-[136px]",
      key: "status",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p
            className="font-gilroy text-white text-[13px] sm:text-[14px] rounded-[8px] px-2"
            style={{ backgroundColor: getBidStatusColor(value) }}
          >
            {L.translate(getBidStatusName(value))}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[13px] sm:text-[14px]">
          {L.translate("CommonForm.contract_sign_status")}
        </p>
      ),
      dataIndex: "contract_signed",
      className: "w-[136px]",
      key: "contract_signed",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p
            className="font-gilroy text-white text-[13px] sm:text-[14px] rounded-[8px] px-2"
            style={{ backgroundColor: getPaidStatusColor(value) }}
          >
            {L.translate(getPaidStatusName(value))}
          </p>
        </div>
      ),
    },

    /*
    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[13px] sm:text-[14px]">
          {L.translate("CollaboratorProfile.height")}
        </p>
      ),
      dataIndex: "height",
      className: "w-auto",
      key: "height",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[13px] sm:text-[14px]">
          {L.translate("CollaboratorProfile.shoe_size")}
        </p>
      ),
      dataIndex: "shoe_size",
      className: "w-auto",
      key: "shoe_size",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {value}
          </p>
        </div>
      ),
    },
    */
  ];

  const mobileColumns: ColumnsType<CollabStatusType> = [
    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[14px] font-medium">
          {L.translate("Base.info")}
        </p>
      ),
      dataIndex: "action",
      className: "w-auto",
      render: (_, record: { key: React.Key }) => {
        const selData = dataSource.filter((item) => item.key === record.key)[0];

        return (
          <div className="w-full flex flex-col items-start justify-between">
            <div className="flex flex-col sm:flex-row items-start justify-between w-full">
              <div className="flex flex-col items-start justify-between">
                <div className="flex flex-row items-start justify-center gap-2">
                  <p className="text-secondary text-[14px] font-gilroy font-medium">
                    {`${L.translate("CommonForm.name")}:`}
                  </p>
                  <p className="text-primary text-[14px] font-gilroy font-medium">
                    {selData.user_name}
                  </p>
                </div>
                <div className="flex flex-row items-center justify-center gap-2">
                  <p className="text-secondary text-[14px] font-gilroy font-medium">
                    {`${L.translate("CommonForm.event_name")}:`}
                  </p>
                  <p className="text-primary text-[12px] font-gilroy font-medium">
                    {selData.event_name}
                  </p>
                </div>
                <div className="flex flex-row items-start justify-center gap-2">
                  <p className="text-secondary text-[14px] font-gilroy font-medium">
                    {`${L.translate("CommonForm.event_date")}:`}
                  </p>
                  <p className="text-primary text-[14px] font-gilroy font-medium">
                    {selData.event_date
                      ? makeFormattedDateString(selData.event_date)
                      : "-"}
                  </p>
                </div>
              </div>
              <div className="flex flex-col items-start justify-between">
                <div className="flex flex-row items-start justify-center gap-2">
                  <p className="text-secondary text-[14px] font-gilroy font-medium">
                    {`${L.translate("CommonForm.comp")}:`}
                  </p>
                  <p className="text-primary text-[14px] font-gilroy font-medium">
                    {makeNumberWithFloatingDigits(selData.comp, 2)}
                  </p>
                </div>
                <div className="flex flex-row items-start justify-center gap-2">
                  <p className="text-secondary text-[14px] font-gilroy font-medium">
                    {`${L.translate("CommonForm.reimburse")}:`}
                  </p>
                  <p className="text-primary text-[14px] font-gilroy font-medium">
                    {makeNumberWithFloatingDigits(selData.reimburse, 2)}
                  </p>
                </div>
                <div className="flex flex-row items-start justify-center gap-2">
                  <p className="text-secondary text-[14px] font-gilroy font-medium">
                    {`${L.translate("CommonForm.contract_sign_status")}`}
                  </p>
                  <div className="flex flex-row gap-2 items-center">
                    <p
                      className="font-gilroy text-white text-[14px] rounded-[8px] px-2"
                      style={{
                        backgroundColor: getPaidStatusColor(
                          selData.contract_signed
                        ),
                      }}
                    >
                      {L.translate(getPaidStatusName(selData.contract_signed))}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className="mt-8 w-full relative">
      <div className="flex flex-col items-start justify-center gap-2 md:flex-row md:items-center md:justify-start md:gap-4 mb-2">
        <div className="text-primary text-[16px] font-gilroy-semibold">
          {L.translate("CommonForm.collaborator_statistics_month")}
        </div>
        <MonthPicker
          placeholder={String(L.translate("CommonForm.event_start_date"))}
          value={searchParams.event_start_date ?? ""}
          onChange={(strDate?: string) =>
            setSearchParams({
              ...searchParams,
              event_start_date: strDate ?? "",
              page: 1,
            })
          }
        />
         <MonthPicker
          placeholder={String(L.translate("CommonForm.event_end_date"))}
          value={searchParams.event_end_date ?? ""}
          onChange={(strDate?: string) =>
            setSearchParams({
              ...searchParams,
              event_end_date: strDate ?? "",
              page: 1,
            })
          }
        />
          <InputWithoutLabel
              placeholder={String(L.translate("CommonForm.event_name"))}
              className="w-50"
              value={searchParams.event_name}
              showClose={searchParams.event_name!.length > 0}
              clickedClose={() =>
                setSearchParams({ ...searchParams, event_name: "", page: 1 })
              }
              onChange={(e) =>
                setSearchParams({
                  ...searchParams,
                  event_name: e.target.value,
                  page: 1,
                })
              }
            />
      </div>
      <Table
        locale={{
          emptyText: (
            <Empty
              description={
                <p className="font-gilroy-semibold text-secondary text-[14px]">
                  {L.translate("Base.no_data")}
                </p>
              }
            />
          ),
        }}
        showSorterTooltip={false}
        columns={isMobile ? mobileColumns : columns}
        dataSource={dataSource}
        loading={isLoading}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => onClickedBid(record),
          };
        }}
        pagination={{
          total,
          current: searchParams.page,
          onChange: onPageChange,
          position: ["bottomCenter"],
          defaultPageSize: LIST_PAGE_SIZE,
          showSizeChanger: false,
        }}
      />
    </div>
  );
};

export default ReportCollaboratorsPage;
